import "src/style/components/SettingsCheckbox.scss";

import { ToggleButton, Typography } from "@mui/material";
import { ReactElement } from "react";
import CheckIcon from "@mui/icons-material/Check";

interface ISettingsCheckboxProps {
    name: string;
    description: string;
    value: boolean;
    onCheck: (value: boolean) => void;
}

export default function SettingsCheckbox({
    name,
    description,
    value,
    onCheck,
}: ISettingsCheckboxProps): ReactElement {
    return (
        <div className="settings-checkbox">
            <Typography
                gutterBottom
                display="block"
                fontSize={16}
                fontWeight={600}
                sx={{ mb: 1 }}
            >
                {name}
            </Typography>
            <div className="settings-checkbox__button">
                <ToggleButton
                    value="check"
                    size="small"
                    selected={value}
                    onChange={() => {
                        onCheck(!value);
                    }}
                >
                    <CheckIcon />
                </ToggleButton>
                {description}
            </div>
        </div>
    );
}
