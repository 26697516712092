import { Gradient } from "src/assets/js/gradient";
import { ReactElement, useEffect } from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useMediaQuery, useTheme } from "@mui/material";

export interface IAnimatedGradientProps {
    gradientId?: string;
}

export default function AnimatedGradient({
    gradientId = "gradient-canvas",
}: IAnimatedGradientProps): ReactElement {
    const theme = useTheme();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const animateBackground = useAppSelector(
        (state) => state.settings.animateBackground,
    );
    const chosenTheme = useAppSelector((state) => state.settings.theme);
    const lightMode = useAppSelector((state) => state.settings.lightMode);

    const computeGradientStyling = (): React.CSSProperties => {
        let color1 = theme.palette.gradientBg?.main;
        let color2 = theme.palette.accent?.light;
        let color3 = theme.palette.secondary.light;
        let color4 = theme.palette.gradientBg?.main;

        if (lightMode !== "" && lightMode === "dark") {
            color2 = theme.palette.accent?.dark;
            color3 = theme.palette.secondary?.dark;
        } else if (lightMode === "" && prefersDarkMode) {
            color2 = theme.palette.accent?.dark;
            color3 = theme.palette.secondary?.dark;
        }

        return {
            "--gradient-color-1": color1,
            "--gradient-color-2": color2,
            "--gradient-color-3": color3,
            "--gradient-color-4": color4,
        } as React.CSSProperties;
    };

    useEffect(() => {
        const gradient = new Gradient();
        gradient.initGradient("#" + gradientId);
    }, [animateBackground, chosenTheme, lightMode]);

    return (
        <canvas
            id={gradientId}
            data-transition-in
            style={computeGradientStyling()}
        />
    );
}
