import "src/style/components/ExportTimesheetsComponent.scss";

import { ReactElement, useState } from "react";
import {
    Button,
    MenuItem,
    Select,
    SelectChangeEvent,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ProgressBar from "src/components/ProgressBar";
import { useAppSelector } from "src/hooks/useAppSelector";
import { fetchWithAuth, getTarget } from "src/utils/api";
import { ExportTimesheetResponse } from "src/types";
import { useDispatch } from "react-redux";
import { setProgressMessage } from "src/store/reducers/appSlice";
import dayjs, { Dayjs } from "dayjs";

export default function ExportTimesheetsComponent(): ReactElement {
    const refreshToken = useAppSelector((state) => state.auth.refresh_token);

    const dispatch = useDispatch();
    const theme = useTheme();

    const [from, setFrom] = useState<Dayjs | null>(dayjs());
    const [to, setTo] = useState<Dayjs | null>(dayjs());

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<JSX.Element>();

    const [employeeTypes, setEmployeeTypes] = useState<string[]>([
        "CIM Contract",
        "SRL Contract",
        "PFA Contract",
        "Partner Company Contract",
    ]);
    const [approvalStatuses, setApprovalStatuses] = useState<string[]>([
        "approved1stLevel",
    ]);

    const [company, setCompany] = useState<string>("");

    const handleEmployeeTypes = (
        event: React.MouseEvent<HTMLElement>,
        newEmployeeTypes: string[],
    ) => {
        newEmployeeTypes.length > 0 && setEmployeeTypes(newEmployeeTypes);
    };

    const handleApprovalStatuses = (
        event: React.MouseEvent<HTMLElement>,
        newApprovalStatuses: string[],
    ) => {
        newApprovalStatuses.length > 0 &&
            setApprovalStatuses(newApprovalStatuses);
    };

    const requestExport = async () => {
        dispatch(setProgressMessage("Starting export job"));
        setLoading(true);

        const requestBody = {
            from: from?.format("DD-MMM-YYYY"),
            to: to?.format("DD-MMM-YYYY"),
            refreshToken,
            company,
            approvalStatuses:
                approvalStatuses.length < 4 ? approvalStatuses : null,
            employeeTypes: employeeTypes.length < 4 ? employeeTypes : null,
        };

        const request = new Request(
            getTarget() + "/api/ExportTimesheets/export",
            {
                method: "post",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            },
        );

        await fetchWithAuth<ExportTimesheetResponse>(request)
            .then((_response) => {
                if (_response.timesheetCount !== 0) {
                    setMessage(
                        <Typography>
                            Successfully initiated export job.{" "}
                            {_response.timesheetCount !== -1 ? (
                                <>
                                    <span
                                        style={{
                                            color: theme.palette.success.main,
                                        }}
                                    >
                                        {_response.timesheetCount}
                                    </span>{" "}
                                    timesheet(s) found. <br />
                                    The exported timesheets will be sent as a
                                    ZIP archive on your email address.{" "}
                                    {_response.timesheetCount < 20 ? (
                                        <strong>
                                            The mail should arrive soon.
                                        </strong>
                                    ) : (
                                        <strong>
                                            The export should be ready in about{" "}
                                            <span
                                                style={{
                                                    color: theme.palette.success
                                                        .main,
                                                }}
                                            >
                                                {Math.floor(
                                                    _response.timesheetCount /
                                                        20,
                                                )}
                                            </span>{" "}
                                            minutes.
                                        </strong>
                                    )}
                                </>
                            ) : (
                                <div>
                                    The exported timesheets will be sent as a
                                    ZIP archive on your email address.
                                </div>
                            )}
                        </Typography>,
                    );
                } else {
                    setMessage(
                        <Typography>
                            <span style={{ color: theme.palette.warning.main }}>
                                No timesheets found for the selected conditions.
                            </span>{" "}
                            Please change the filters or period and start the
                            export job again.
                        </Typography>,
                    );
                }
            })
            .catch((e: Error) => {
                if (e.message.indexOf("401") > -1) {
                    setMessage(
                        <Typography>
                            <span style={{ color: theme.palette.error.main }}>
                                Insufficient permissions!
                            </span>{" "}
                            You must have the Admin role in Zoho People if you
                            need to export timesheets. If you think this is a
                            mistake, please{" "}
                            <strong>submit a ticket in the Help Center</strong>.
                        </Typography>,
                    );
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className="export-timesheets">
            {loading ? (
                <ProgressBar />
            ) : (
                <>
                    <Typography>
                        Request an export of Zoho People timesheets for the
                        selected period. Apply filters as needed.
                    </Typography>
                    <div className="export-timesheets__section--2col">
                        <Typography
                            variant="h6"
                            fontFamily="Syne"
                            fontWeight={600}
                            gutterBottom
                        >
                            Period
                        </Typography>
                        <div className="export-timesheets__period__pickers">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label={"From"}
                                    views={["year", "month"]}
                                    value={from}
                                    onChange={(value) =>
                                        value && setFrom(value)
                                    }
                                    minDate={dayjs("2021-01-01")}
                                    maxDate={to!}
                                />
                                <DatePicker
                                    label={"To"}
                                    views={["year", "month"]}
                                    value={to}
                                    onChange={(value) => value && setTo(value)}
                                    minDate={from!}
                                    maxDate={dayjs()}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className="export-timesheets__section--2col">
                        <Typography
                            variant="h6"
                            fontFamily="Syne"
                            fontWeight={600}
                            gutterBottom
                        >
                            Filters
                        </Typography>
                        <div>
                            <Typography variant="body2" gutterBottom>
                                Employee type
                            </Typography>
                            <ToggleButtonGroup
                                value={employeeTypes}
                                onChange={handleEmployeeTypes}
                                aria-label="employee types"
                                color="secondary"
                                fullWidth
                            >
                                <ToggleButton value="CIM Contract">
                                    CIM
                                </ToggleButton>
                                <ToggleButton value="SRL Contract">
                                    SRL
                                </ToggleButton>
                                <ToggleButton value="PFA Contract">
                                    PFA
                                </ToggleButton>
                                <ToggleButton value="Partner Company Contract">
                                    Partner Company
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div>
                            <Typography variant="body2" gutterBottom>
                                Approval status
                            </Typography>
                            <ToggleButtonGroup
                                value={approvalStatuses}
                                onChange={handleApprovalStatuses}
                                aria-label="approval statuses"
                                color="secondary"
                                fullWidth
                            >
                                <ToggleButton value="draft">Draft</ToggleButton>
                                <ToggleButton value="pending">
                                    Pending
                                </ToggleButton>
                                <ToggleButton value="approved1stLevel">
                                    Approved 1st Level
                                </ToggleButton>
                                <ToggleButton value="approved">
                                    Fully Approved
                                </ToggleButton>
                                <ToggleButton value="rejected">
                                    Rejected
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div>
                            <Typography variant="body2" gutterBottom>
                                Company
                            </Typography>
                            <Select
                                value={company}
                                onChange={(e: SelectChangeEvent) =>
                                    setCompany(e.target.value)
                                }
                                sx={{ width: "100%" }}
                            >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="Principal Leryon Services">
                                    Principal Leryon Services
                                </MenuItem>
                                <MenuItem value="Principal33 Spain">
                                    Principal33 Spain
                                </MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="export-timesheets__button-area">
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={requestExport}
                        >
                            Start Export
                        </Button>
                        <div className="export-timesheets__button-area__message">
                            {message}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
