import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC, ReactElement, useEffect } from "react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
    setDataFetchedTime,
    setProgressStage,
} from "src/store/reducers/appSlice";
import { setDefaultProject } from "src/store/reducers/settingsSlice";
import {
    setEmployee,
    setProjects,
    setSelectedProject,
} from "src/store/reducers/userSlice";
import { EmployeeDto, ProgressStage, Project, SummaryResult } from "src/types";
import { fetchWithAuth, getTarget } from "src/utils/api";

const ProjectComponent: FC = (): ReactElement => {
    const dispatch = useAppDispatch();

    const dataFetchedTime = useAppSelector(
        (state) => state.app.dataFetchedTime,
    );
    const projects = useAppSelector((state) => state.user.projects);
    const selectedProject = useAppSelector(
        (state) => state.user.selectedProject,
    );
    const defaultProject = useAppSelector(
        (state) => state.settings.defaultProject,
    );

    const handleProjectChange = (event: SelectChangeEvent): void => {
        dispatch(setProgressStage(0));
        const _projectName = event.target.value as string;
        const _project = projects.find(
            (_p) => _p.projectName === _projectName,
        ) as Project;

        if (_project) {
            dispatch(setSelectedProject(_project));
        }
    };

    const fetchData = async () => {
        dispatch(setProgressStage(-99));
        await fetchWithAuth<EmployeeDto>(
            getTarget() + "/api/ZohoUser/employee",
        ).then(async (_employee) => {
            dispatch(setEmployee(_employee));
            dispatch(setProgressStage(0));
            const request = new Request(getTarget() + "/api/ZohoUser/summary", {
                method: "post",
                body: JSON.stringify(_employee),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            });
            await fetchWithAuth<SummaryResult>(request).then((_summary) => {
                if (_summary.projects) {
                    dispatch(setProjects(_summary.projects));
                    handleDefaultProject(_summary.projects);
                }

                dispatch(setDataFetchedTime(new Date().getTime()));
            });
        });
    };

    const handleDefaultProject = (projects: Project[]) => {
        if (projects.length > 0) {
            if (defaultProject !== "") {
                const defP: Project = projects.filter(
                    (p) => p.projectName === defaultProject,
                )[0];
                if (defP) {
                    dispatch(setSelectedProject(defP));
                    dispatch(setProgressStage(0));
                } else {
                    // default project no longer assigned to user
                    dispatch(setDefaultProject(""));
                    dispatch(setSelectedProject(projects[0]));
                    dispatch(setProgressStage(0));
                }
            } else {
                if (projects?.length > 1) {
                    dispatch(setSelectedProject(null));
                } else {
                    dispatch(setSelectedProject(projects[0]));
                    dispatch(setProgressStage(0));
                }
            }
        } else {
            dispatch(setSelectedProject(null));
            if (defaultProject !== "") {
                dispatch(setDefaultProject(""));
            }
            dispatch(
                setProgressStage(ProgressStage.DISABLED_NO_PROJECTS_ASSIGNED),
            );
        }
    };

    useEffect(() => {
        if (
            !dataFetchedTime ||
            dataFetchedTime + 60 * 1000 < new Date().getTime()
        ) {
            fetchData();
        } else {
            handleDefaultProject(projects);
        }
    }, []);

    return (
        <div>
            <Select
                value={selectedProject ? selectedProject.projectName : ""}
                onChange={handleProjectChange}
                displayEmpty
                inputProps={{
                    "aria-label": "Without label",
                    MenuProps: { disableScrollLock: true },
                }}
                size="small"
                sx={{ marginTop: "1rem", width: "100%", maxWidth: 300 }}
            >
                {projects?.map((project) => (
                    <MenuItem
                        value={project.projectName}
                        key={project.projectId}
                    >
                        {project.projectName}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default ProjectComponent;
