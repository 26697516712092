import ReactDOM from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "src/index.css";
import App from "src/App";
import store from "src/store/store";
import { OutHookConfigurator } from "src/components/OutHookConfigurator";
import { msalConfig } from "src/utils/auth";
import { saveState } from "src/utils/storage";

const debounce = require("debounce");

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
    store.subscribe(
        // we use debounce to save the state once each 800ms
        // for better performances in case multiple changes occur in a short time
        debounce(() => {
            saveState(store.getState());
        }, 800),
    );

    const baseUrl = document
        .getElementsByTagName("base")[0]
        .getAttribute("href") as string;
    const rootElement = document.getElementById("root") as HTMLElement;
    const root = ReactDOM.createRoot(rootElement);

    root.render(
        <Provider store={store}>
            <MsalProvider instance={msalInstance}>
                <BrowserRouter basename={baseUrl}>
                    <OutHookConfigurator />
                    <App />
                </BrowserRouter>
            </MsalProvider>
        </Provider>,
    );
});

export { msalInstance };
