import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC, ReactElement } from "react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { setProgressStage } from "src/store/reducers/appSlice";
import { setSelectedProject } from "src/store/reducers/userSlice";
import { Project } from "src/types";

const ProjectComponent: FC = (): ReactElement => {
    const dispatch = useAppDispatch();

    const projects = useAppSelector((state) => state.user.projects);
    const selectedProject = useAppSelector(
        (state) => state.user.selectedProject,
    );

    const handleProjectChange = (event: SelectChangeEvent): void => {
        dispatch(setProgressStage(0));
        const _projectName = event.target.value as string;
        const _project = projects.find(
            (_p) => _p.projectName === _projectName,
        ) as Project;

        if (_project) {
            dispatch(setSelectedProject(_project));
        }
    };

    return (
        <div>
            <Select
                value={selectedProject ? selectedProject.projectName : ""}
                onChange={handleProjectChange}
                displayEmpty
                inputProps={{
                    "aria-label": "Without label",
                    MenuProps: { disableScrollLock: true },
                }}
                size="small"
                sx={{ marginTop: "1rem", width: "100%", maxWidth: 300 }}
            >
                {projects?.map((project) => (
                    <MenuItem
                        value={project.projectName}
                        key={project.projectId}
                    >
                        {project.projectName}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default ProjectComponent;
