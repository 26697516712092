import { RootState } from "src/store/store";

const KEY_REDUX = "redux";

export function loadState(): any {
    try {
        const serializedState = localStorage.getItem(KEY_REDUX);
        if (!serializedState) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
}

export function saveState(state: RootState): void {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(KEY_REDUX, serializedState);
    } catch (e) {
        // Ignore
    }
}
