import { Theme } from "@mui/material";

export enum AppRoles {
    ADMIN = "TimesheetsAdminGroup",
    ACCOUNT_MANAGER = "TimesheetsAccountManagerGroup",
    PEOPLE_MANAGER = "TimesheetsTDMGroup",
    BACKOFFICE = "TimesheetsBackofficeGroup",
}

export interface ThemeDictionary {
    [key: string]: Theme;
}

export interface AspMvcErrorResponse {
    errors?: AspMvcErrorResponseErrorsDictionary;
    type: string;
    title: string;
    status: number;
    traceId: string;
}

export interface AspMvcErrorResponseErrorsDictionary {
    [key: string]: string[];
}

export enum ProgressStage {
    INITIAL_LOAD = -99,
    DISABLED_NO_PROJECTS_ASSIGNED = -10,
    DISABLED_ERRORS = -1,
    INITIAL = 0,
    MULTIPLE_FILES = 1,
    UPLOADING = 2,
    VALIDATE_TIMELOGS = 3,
    TIMELOG_ERRORS = 4,
    REFRESH = 5,
    DELETE = 6,
    UPLOADED = 10,
    UPLOADED_NO_SUBMIT = 11,
    UPLOADED_ERRORS = 12,
    SUBMITTED = 20,
    FINISHED = 99,
}

export interface ZohoAuthResult {
    access_token: string;
    refresh_token: string;
    scope: string;
    expires_in: number;
}

export interface SummaryResult {
    projects: Project[];
    results: MonthlySummary[];
}

export interface Project {
    projectName: string;
    projectId: string;
    clientName: string;
    clientId: string;
}

export interface ProjectSummary {
    projectId: string;
    workedHours: number;
}

export interface MonthlySummary {
    month: string;
    projectSummaries: ProjectSummary[];
}

export enum EmployeeType {
    CIM = "CIM Contract",
    SRL = "SRL Contract",
    PFA = "PFA Contract",
    PartnerCompany = "Partner Company Contract",
    Probono = "Probono",
}

export interface EmployeeDto {
    clientId?: string;
    department?: string;
    location?: string;
    fullEmployeeId?: string;
    dateOfJoining?: string;
}

export interface TimeLog {
    user: string;
    timeLogId?: string;
    jobName?: string;
    jobId?: string;
    projectName?: string;
    projectId?: string;
    workDate: string;
    dateFormat?: string;
    hours?: string;
    fromTime?: string;
    toTime?: string;
    timer?: string;
    timeFormat?: string;
    workItem: string;
    description?: string;
    isDeleteAllowed: boolean;
}

export enum ApiErrorSeverity {
    Ignore = "Ignore",
    Severe = "Severe",
    Minor = "Minor",
    File = "File",
    Employee = "Employee",
    Reject = "Reject",
}

export interface ApiError {
    message: string;
    severity: ApiErrorSeverity;
    metadata?: ApiErrorMetadata[];
}

export interface ApiErrorMetadata {
    recordId?: string;
    viewName?: string;
    recordUrl?: string;
}

export interface DeleteInfo {
    month: number;
    year: number;
}

export interface SignalRProgressMessage {
    message: string;
    currentProgress?: number;
    maximumProgress?: number;
}

export interface UploadResponse {
    timeLogs: Array<TimeLog>;
    validationErrors: Array<ApiError>;
}

export interface UploadTimeLogDto {
    row: number;
    timeLogData: TimeLog;
    errors: Array<ApiError>;
    errorStatus: number;
}

export interface ConfirmTimeLogsRequest {
    timeLogs: UploadTimeLogDto[];
}

export interface ConfirmTimeLogsResponse {
    timeLogs: UploadTimeLogDto[];
}

export interface CreateTimesheetResponse {
    timesheetId: string[];
}

export interface ExportTimesheetResponse {
    timesheetCount: number;
}

export interface GenericMessageResponse {
    message: string;
}
