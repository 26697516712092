import React, {
    ChangeEvent,
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from "react";
import "src/style/components/DragAndDrop.scss";

import { Typography, useTheme } from "@mui/material";
import { useAppSelector } from "src/hooks/useAppSelector";
import { ApiErrorSeverity, ProgressStage } from "src/types";
import { clearErrors } from "src/store/reducers/appSlice";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import ApiErrorComponent from "src/components/ApiErrorComponent";
import ProgressBar from "src/components/ProgressBar";

interface IDragAndDropProps {
    allowedExtensions: string[];
    isLoading: boolean;
    dropHandler: (file: File) => void;
}

const DragAndDrop: FC<IDragAndDropProps> = ({
    allowedExtensions,
    dropHandler,
    isLoading,
}): ReactElement => {
    const dispatch = useAppDispatch();
    const progressStage = useAppSelector((state) => state.app.progressStage);
    const selectedProject = useAppSelector(
        (state) => state.user.selectedProject,
    );
    const errors = useAppSelector((state) => state.app.errors);

    const [isDragging, setIsDragging] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const theme = useTheme();

    const [message, setMessage] = useState(
        "Drag & drop your file inside this area",
    );

    const handleDrag = (e: React.DragEvent<HTMLElement>): void => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragIn = (e: React.DragEvent<HTMLElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        const _dragCounter = dragCounter + 1;
        setDragCounter(_dragCounter);
        if (
            e.dataTransfer.items &&
            e.dataTransfer.items.length > 0 &&
            (progressStage === 0 || progressStage === 1)
        ) {
            setIsDragging(true);
        }
    };

    const handleDragOut = (e: React.DragEvent<HTMLElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        const _dragCounter = dragCounter - 1;
        setDragCounter(_dragCounter);
        if (_dragCounter === 0) {
            setIsDragging(false);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        if (progressStage <= -1 || progressStage > 1) {
            return;
        }

        setIsDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            dispatch(clearErrors());
            const file = e.dataTransfer.items[0].getAsFile();

            handleUpload(file as File);

            e.dataTransfer.clearData();
            setDragCounter(0);
        }
    };

    const handleUpload = (file: File): void => {
        dropHandler(file);
    };

    const hasFatalError = (): boolean => {
        return errors?.some(
            (e) =>
                e.severity ===
                (ApiErrorSeverity.Employee || ApiErrorSeverity.Reject),
        );
    };

    const onFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files;
        if (files) {
            handleUpload(files[0]);
        }
    };

    const computeBorder = (): string => {
        if (isDragging) {
            return `2px dashed ${theme.palette.secondary.main}`;
        }
        if (progressStage === -1 || hasFatalError()) {
            return `1px dashed ${theme.palette.error.main}`;
        }

        return `1px dashed ${theme.palette.mediumGray.main}`;
    };

    useEffect(() => {
        if (progressStage === ProgressStage.DISABLED_ERRORS) {
            setMessage("There are errors on your Zoho employee profile.");
        }
        if (progressStage >= 0) {
            if (selectedProject === null) {
                setMessage(
                    "Please select a project using the drop-down in the top-left corner.",
                );
            } else {
                setMessage("Drag & drop your file inside this area");
            }
        }
    }, [progressStage, selectedProject]);

    return (
        <div
            className="drag-and-drop"
            onDragEnter={handleDragIn}
            onDragLeave={handleDragOut}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            style={{
                backgroundColor: theme.palette.background.default,
                border: computeBorder(),
            }}
        >
            {isLoading ? (
                <ProgressBar />
            ) : (
                <div>
                    <div
                        className="drag-and-drop__no-files"
                        style={{ color: theme.palette.darkGray.main }}
                    >
                        {!hasFatalError() && (
                            <Typography
                                variant="h4"
                                className="drag-and-drop__message"
                                align="center"
                            >
                                <span
                                    style={{
                                        color: isDragging
                                            ? theme.palette.secondary.main
                                            : "inherit",
                                    }}
                                >
                                    {message}
                                </span>
                                {progressStage >= 0 &&
                                    selectedProject != null && (
                                        <span className="drag-and-drop__file-input">
                                            {" "}
                                            OR{" "}
                                            <button
                                                onClick={() =>
                                                    fileInputRef.current?.click()
                                                }
                                                style={{
                                                    color: theme.palette
                                                        .secondary.main,
                                                }}
                                            >
                                                click here to upload a file
                                            </button>
                                            <input
                                                type="file"
                                                title="file-input"
                                                ref={fileInputRef}
                                                onChange={onFileInputChange}
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/pdf"
                                            />
                                        </span>
                                    )}
                            </Typography>
                        )}
                        {errors && (
                            <div className="drag-and-drop__error-wrapper">
                                {errors?.map((e, index) => (
                                    <ApiErrorComponent key={index} error={e} />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DragAndDrop;
