import { UploadTimeLogDto } from "src/types";

export function getTimeLogTotalHours(timeLogs: UploadTimeLogDto[]): number {
    let totalMinutes = 0;

    timeLogs.forEach((t) => {
        let hours = 0;
        let minutes = 0;

        if (t.timeLogData.hours) {
            [hours, minutes] = t.timeLogData.hours!.split(":").map(Number);
        } else if (t.timeLogData.fromTime && t.timeLogData.toTime) {
            [hours, minutes] = fromToTimeToHours(
                t.timeLogData.fromTime!,
                t.timeLogData.toTime!,
            );
        } else {
            // ignore flag
            return;
        }

        totalMinutes += hours * 60 + minutes;
    });

    const totalHours = totalMinutes / 60;

    return totalHours;
}

export function decimalHourToString(input: number): string {
    const hours = Math.floor(input);
    const minutes = Math.round((input - hours) * 60);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
}

export function fromToTimeToHours(
    fromTime: string,
    toTime: string,
): Array<number> {
    const startDate = parseTimeSpan(fromTime);
    const endDate = parseTimeSpan(toTime);

    let diff = endDate.getTime() - startDate.getTime();
    if (diff < 0) {
        diff += 24 * 60 * 60 * 1000; // Add 24 hours if the difference is negative
    }

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return [hours, minutes];
}

export function fromToTimeToHoursString(
    fromTime: string,
    toTime: string,
): string {
    const [hours, minutes] = fromToTimeToHours(fromTime, toTime);

    return `${hours < 10 ? "0" : ""}${hours}:${
        minutes < 10 ? "0" : ""
    }${minutes}`;
}

function parseTimeSpan(time: string): Date {
    const [timePart, modifier] = time.split(/(AM|PM)/);
    let [hours, minutes] = timePart.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
        hours += 12;
    } else if (modifier === "AM" && hours === 12) {
        hours = 0;
    }

    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
}

export function toCompanyDateFormat(input: Date | string): string {
    let date: Date;

    if (typeof input === "string") {
        date = new Date(input); // Convert string to Date object
        console.log("string");
    } else if (input instanceof Date) {
        console.log("Input is a Date object:", input); // Debugging log
        date = input; // Already a Date object
    } else {
        throw new Error(
            "Invalid input type. Expected a Date object or an ISO 8601 string.",
        );
    }

    const day = String(date.getDate()).padStart(2, "0");
    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}
