import "src/style/WhatsNewPage.scss";

import { ReactElement } from "react";
import { Button, Paper, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function WhatsNewPage(): ReactElement {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <div className="whats-new-page">
            <Paper
                className="whats-new-page__content"
                elevation={0}
                sx={{
                    borderRadius: 0,
                    borderColor: theme.palette.primary.main,
                }}
            >
                <div className="whats-new-page__title">
                    <Typography variant="h2" align="center" gutterBottom>
                        Timesheet Automation Tool{" "}
                        <span style={{ color: theme.palette.secondary.main }}>
                            3
                        </span>{" "}
                        is here!
                    </Typography>
                    <Typography variant="h6" align="center">
                        Optimized. Prettier. Simpler.
                    </Typography>
                </div>
                <div className="whats-new-page__content__sections-wrapper">
                    <div className="whats-new-page__content__section">
                        <span
                            className="whats-new-page__content__section__chip"
                            style={{
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.secondary.contrastText,
                            }}
                        >
                            optimized
                        </span>
                        <Typography variant="h4" gutterBottom>
                            Faster than ever at handling your timekeeping
                        </Typography>
                        <ul>
                            <Typography component="li" gutterBottom>
                                Now using an integrated React SPA (running on
                                same server, faster packaging and deployment,
                                easier project management)
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Upgraded to .NET 8
                            </Typography>
                            <Typography component="li" gutterBottom>
                                All frontend completely rewritten in TypeScript
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Seamlessly rewritten authentication flow to both
                                Microsoft and Zoho systems
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Zoho API microservice completely reworked
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Massive clean-up of obsolete code and features
                            </Typography>
                        </ul>
                    </div>
                    <div className="whats-new-page__content__section">
                        <span
                            className="whats-new-page__content__section__chip"
                            style={{
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.secondary.contrastText,
                            }}
                        >
                            prettier
                        </span>
                        <Typography variant="h4" gutterBottom>
                            Looks don't matter but we thought to make it nicer
                            for you anyway
                        </Typography>
                        <ul>
                            <Typography component="li" gutterBottom>
                                Completely new redesign using the new company
                                branding
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Dark-mode support
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Multiple color themes available
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Easier on the eyes overall
                            </Typography>
                        </ul>
                    </div>
                    <div className="whats-new-page__content__section">
                        <span
                            className="whats-new-page__content__section__chip"
                            style={{
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.secondary.contrastText,
                            }}
                        >
                            simpler
                        </span>
                        <Typography variant="h4" gutterBottom>
                            Add your time logs into Zoho People without hassle
                        </Typography>
                        <ul>
                            <Typography component="li" gutterBottom>
                                Preview the time logs generated by the app now
                                before adding them to Zoho People
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Upload flow completely rethought, using a
                                "steps" approach
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Automatically attach your file to the submitted
                                timesheet
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Files are now identified by column names instead
                                of positions, thus the order of the columns no
                                longer matters
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Errors are not so vague anymore, instead being
                                linked to the relevant time logs
                            </Typography>
                            <Typography component="li" gutterBottom>
                                The corresponding Job mapping is now read from
                                Zoho People instead of being hard-coded
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Account managers can now modify or create
                                accepted timesheet files at run-time
                            </Typography>
                        </ul>
                    </div>
                </div>
                <div className="whats-new-page__content__button">
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        onClick={() => navigate("/home")}
                        sx={{ fontSize: 20, padding: 2 }}
                    >
                        get started now
                    </Button>
                </div>
            </Paper>
        </div>
    );
}
