import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { ReactElement, useState } from "react";
import { UploadTimeLogDto } from "src/types";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
    decimalHourToString,
    fromToTimeToHoursString,
    getTimeLogTotalHours,
} from "src/utils/misc";

interface ITimeLogsTableGroupedRowProps {
    row: [key: string, data: UploadTimeLogDto[]];
    highlightErrors: boolean;
    rowStyleFn: (errorStatus: number) => React.CSSProperties;
}

export default function TimeLogsTableGroupedRow({
    row,
    highlightErrors,
    rowStyleFn,
}: ITimeLogsTableGroupedRowProps): ReactElement {
    const [open, setOpen] = useState(false);
    const dateFormat = useAppSelector((state) => state.settings.dateFormat);

    return (
        <>
            <TableRow
                sx={{
                    "&:last-child td, &:last-child th": {
                        border: 0,
                    },
                    ...(highlightErrors
                        ? rowStyleFn(
                              row[1].reduce(function (prev, current) {
                                  return prev &&
                                      prev.errorStatus > current.errorStatus
                                      ? prev
                                      : current;
                              }).errorStatus,
                          )
                        : undefined),
                    "& > *": { borderBottom: "unset" },
                }}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell
                    component="th"
                    scope="row"
                    sx={
                        highlightErrors
                            ? highlightErrors
                                ? rowStyleFn(
                                      row[1]!.reduce(function (prev, current) {
                                          return prev &&
                                              prev.errorStatus >
                                                  current.errorStatus
                                              ? prev
                                              : current;
                                      }).errorStatus,
                                  )
                                : undefined
                            : undefined
                    }
                >
                    {new Date(row[0]).toLocaleDateString(
                        dateFormat === "" ? undefined : dateFormat,
                        {
                            day: "numeric",
                            month: dateFormat === "" ? "numeric" : "long",
                            year: "numeric",
                        },
                    )}
                </TableCell>
                <TableCell
                    sx={
                        highlightErrors
                            ? highlightErrors
                                ? rowStyleFn(
                                      row[1]!.reduce(function (prev, current) {
                                          return prev &&
                                              prev.errorStatus >
                                                  current.errorStatus
                                              ? prev
                                              : current;
                                      }).errorStatus,
                                  )
                                : undefined
                            : undefined
                    }
                >
                    {decimalHourToString(getTimeLogTotalHours(row[1]!))}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Table size="small" aria-label="time logs">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Row</TableCell>
                                        <TableCell align="right">Job</TableCell>
                                        <TableCell align="right">
                                            Work&nbsp;item
                                        </TableCell>
                                        <TableCell align="right">
                                            Hours
                                        </TableCell>
                                        <TableCell align="right">
                                            Errors
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row[1].map((t, index) => (
                                        <TableRow
                                            key={t.row}
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    {
                                                        border: 0,
                                                    },
                                                ...(highlightErrors
                                                    ? rowStyleFn(t.errorStatus)
                                                    : undefined),
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                sx={
                                                    highlightErrors
                                                        ? highlightErrors
                                                            ? rowStyleFn(
                                                                  t.errorStatus,
                                                              )
                                                            : undefined
                                                        : undefined
                                                }
                                            >
                                                {t.row}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={
                                                    highlightErrors
                                                        ? rowStyleFn(
                                                              t.errorStatus,
                                                          )
                                                        : undefined
                                                }
                                            >
                                                {t.timeLogData.jobName}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={
                                                    highlightErrors
                                                        ? rowStyleFn(
                                                              t.errorStatus,
                                                          )
                                                        : undefined
                                                }
                                            >
                                                {t.timeLogData.workItem}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={
                                                    highlightErrors
                                                        ? rowStyleFn(
                                                              t.errorStatus,
                                                          )
                                                        : undefined
                                                }
                                            >
                                                {t.timeLogData.hours
                                                    ? t.timeLogData.hours
                                                    : t.timeLogData.fromTime &&
                                                      t.timeLogData.toTime &&
                                                      fromToTimeToHoursString(
                                                          t.timeLogData
                                                              .fromTime!,
                                                          t.timeLogData.toTime!,
                                                      )}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={
                                                    highlightErrors
                                                        ? rowStyleFn(
                                                              t.errorStatus,
                                                          )
                                                        : undefined
                                                }
                                            >
                                                <ul>
                                                    {t.errors.map(
                                                        (e, index) => (
                                                            <li key={index}>
                                                                {e.message}
                                                                {e.metadata &&
                                                                    e.metadata.map(
                                                                        (
                                                                            em,
                                                                            i,
                                                                        ) => (
                                                                            <IconButton
                                                                                key={
                                                                                    i
                                                                                }
                                                                                aria-label="Open record"
                                                                                color="secondary"
                                                                                size="small"
                                                                            >
                                                                                <a
                                                                                    href={
                                                                                        em.recordUrl
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    <OpenInNewIcon />
                                                                                </a>
                                                                            </IconButton>
                                                                        ),
                                                                    )}
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
