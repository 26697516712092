import "src/style/components/CustomTabPanel.scss";

import { FC, ReactElement } from "react";

interface ITabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const CustomTabPanel: FC<ITabPanelProps> = ({
    children,
    value,
    index,
    ...other
}): ReactElement => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className="tab-panel fill-expand"
        >
            {value === index && children}
        </div>
    );
};

export default CustomTabPanel;
