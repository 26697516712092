import "src/style/components/Layout.scss";

import { Drawer, Fab, useTheme } from "@mui/material";
import { FC, ReactElement, useState } from "react";
import AnimatedGradient from "src/components/AnimatedGradient";
import Footer from "src/components/Footer";
import Navbar from "src/components/Navbar";
import { useAppSelector } from "src/hooks/useAppSelector";

import FeedbackIcon from "@mui/icons-material/Feedback";
import FeedbackComponent from "src/components/FeedbackComponent";
import FatalErrorModal from "src/components/FatalErrorModal";

interface ILayoutProps {
    children: ReactElement;
}

const Layout: FC<ILayoutProps> = ({ children }): ReactElement => {
    const theme = useTheme();

    const animateBackground = useAppSelector(
        (state) => state.settings.animateBackground,
    );

    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setDrawerOpen(newOpen);
    };

    return (
        <div className="layout">
            <Navbar />
            {animateBackground ? (
                <AnimatedGradient />
            ) : (
                <div
                    className="home__background"
                    style={{
                        backgroundColor: theme.palette.background.default,
                    }}
                />
            )}
            <div className="layout__page">{children}</div>
            <Footer />
            <Fab
                color="secondary"
                aria-label="feedback"
                className="feedback-fab"
                variant="extended"
                onClick={toggleDrawer(true)}
            >
                <FeedbackIcon sx={{ mr: 1 }} />
                Feedback
            </Fab>
            <Drawer
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                anchor="bottom"
            >
                <FeedbackComponent closeFn={toggleDrawer(false)} />
            </Drawer>
            <FatalErrorModal />
        </div>
    );
};

export default Layout;
