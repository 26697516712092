import {
    Configuration,
    IPublicClientApplication,
    RedirectRequest,
} from "@azure/msal-browser";
import { redirect } from "react-router-dom";
import {
    setAccessToken,
    setCreationTime,
    setExpiresIn,
} from "src/store/reducers/authSlice";
import store from "src/store/store";
import { ZohoAuthResult } from "src/types";
import { getTarget } from "src/utils/api";

export const authRequest: RedirectRequest = {
    scopes: [process.env.REACT_APP_MSAL_CLIENT_SCOPE as string],
};

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID as string,
        authority: process.env.REACT_APP_MSAL_TENANT_AUTHORITY_URI as string,
        redirectUri: window.location.origin + "/login",
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export async function zohoAcquireTokenSilent(): Promise<string> {
    // check is user is logged in
    const { access_token, refresh_token, creation_time, expires_in } =
        store.getState().auth;

    if (access_token) {
        console.log("Access token found!");

        if (creation_time! + expires_in! * 1000 > new Date().getTime()) {
            console.log("Access token still valid. Using it!");
            return access_token;
        } else {
            console.log("Access token expired! Getting a new one...");

            return refreshToken(refresh_token!);
        }
    } else {
        // check if refresh token exists
        // log in to zoho
        if (refresh_token) {
            console.log(
                "Access token missing but refresh token found! Refreshing...",
            );
            return refreshToken(refresh_token);
        } else {
            console.log("Redirect to login");
            redirect("/login");
        }
    }
    return "";
}

async function refreshToken(refreshToken: string): Promise<string> {
    const refreshResponse = await fetch(
        getTarget() + "/api/ZohoToken/refresh/" + refreshToken,
    );

    if (refreshResponse.ok) {
        let responseJson = await refreshResponse.json();
        const newTokens: ZohoAuthResult = responseJson;
        store.dispatch(setAccessToken(newTokens.access_token));
        store.dispatch(setExpiresIn(newTokens.expires_in));
        store.dispatch(setCreationTime(new Date().getTime()));
        return newTokens.access_token;
    } else {
        throw new Error(
            `${refreshResponse.status} ${refreshResponse.statusText}`,
        );
    }
}

export function zohoAuthRequest(): void {
    window.location.href = `${
        process.env.REACT_APP_ZOHO_AUTHORITY
    }/auth?client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}&scope=${
        process.env.REACT_APP_ZOHO_SCOPE
    }&redirect_uri=${
        window.location.origin + "/login"
    }&response_type=code&access_type=offline&prompt=consent`;
}

export async function zohoTokenRequest(
    code: string,
): Promise<ZohoAuthResult | null> {
    return new Promise<ZohoAuthResult | null>(async (resolve, reject) => {
        const response = await fetch(
            `${getTarget()}/api/ZohoToken/token?code=${code}&redirect_uri=${
                window.location.origin + "/login"
            }`,
        );

        console.log(response.status);
        if (response.status !== 200) {
            const error = await response.json();
            return reject(error);
        } else {
            const result = (await response.json()) as ZohoAuthResult;
            return resolve(result);
        }
    });
}

export function getRoles(
    msalInstance: IPublicClientApplication,
): string[] | undefined {
    const allAccounts = msalInstance.getAllAccounts();
    if (allAccounts.length > 0) {
        const account = allAccounts[0];
        return account.idTokenClaims?.roles;
    } else {
        return undefined;
    }
}
