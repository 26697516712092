import "src/style/NotFoundPage.scss";

import { ReactElement } from "react";
import { Typography } from "@mui/material";

export default function NotFoundPage(): ReactElement {
    return (
        <div className="not-found">
            <div className="not-found__content">
                <img
                    alt=""
                    src="https://www.principal33.com/wp-content/uploads/it-crowd-404.gif"
                    className="not-found__content__image"
                />
                <Typography variant="h1" align="center">
                    404
                </Typography>
                <Typography variant="h4" align="center" gutterBottom>
                    Page not found
                </Typography>
                <Typography align="center" gutterBottom>
                    The requested page could not be found.
                </Typography>
            </div>
        </div>
    );
}
