import "src/style/components/UtilsComponent.scss";
import {
    Button,
    Stack,
    Switch,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { ReactElement, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAppSelector } from "src/hooks/useAppSelector";
import { fetchWithAuth, getTarget } from "src/utils/api";
import { AppRoles, GenericMessageResponse } from "src/types";
import LoadingText from "src/components/LoadingText";
import dayjs, { Dayjs } from "dayjs";
import { getRoles } from "src/utils/auth";
import { useMsal } from "@azure/msal-react";

export default function UtilsComponent(): ReactElement {
    const theme = useTheme();
    const { instance } = useMsal();

    const [deleteTlDate, setDeleteTlDate] = useState<Dayjs | null>(dayjs());
    const [deleteTlUser, setDeleteTlUser] = useState<string>("");
    const [deleteTlAll, setDeleteTlAll] = useState(false);
    const [deleteTlLoading, setDeleteTlLoading] = useState(false);
    const [deleteTlMessage, setDeleteTlMessage] = useState<JSX.Element>();

    const [roles] = useState(getRoles(instance));

    const errors = useAppSelector((state) => state.app.errors);

    const handleDeleteTl = async (): Promise<void> => {
        setDeleteTlLoading(true);

        const request = new Request(
            getTarget() +
                `/api/zohotimelog?month=${deleteTlDate?.format(
                    "DD-MMM-YYYY",
                )}&user=${deleteTlUser}&deleteAll=${deleteTlLoading}`,
            {
                method: "delete",
            },
        );

        await fetchWithAuth<GenericMessageResponse>(request)
            .then((_response) => {
                setDeleteTlMessage(
                    <Typography style={{ color: theme.palette.success.main }}>
                        {_response.message}
                    </Typography>,
                );
            })
            .catch((e: Error) => {
                setDeleteTlMessage(
                    <Typography style={{ color: theme.palette.error.main }}>
                        {errors[0]?.message}
                    </Typography>,
                );

                if (e.message.indexOf("403") > -1) {
                    setDeleteTlMessage(
                        <Typography>
                            <span style={{ color: theme.palette.error.main }}>
                                Insufficient permissions!
                            </span>{" "}
                        </Typography>,
                    );
                }

                if (e.message.indexOf("404") > -1) {
                    setDeleteTlMessage(
                        <Typography>
                            <span style={{ color: theme.palette.error.main }}>
                                No deletable time logs found for specified
                                month.
                            </span>{" "}
                        </Typography>,
                    );
                }
            })
            .finally(() => setDeleteTlLoading(false));
    };

    return (
        <div className="utils-component">
            <div className="utils-component__section">
                <Typography
                    fontSize={22}
                    display="block"
                    fontFamily="Syne"
                    fontWeight={600}
                    gutterBottom
                    marginTop={2}
                >
                    <span style={{ fontWeight: 800 }}>Delete </span> time logs
                    for the{" "}
                    <span style={{ fontWeight: 800 }}>entire month</span>
                </Typography>
                <Typography>
                    Sometimes, the upload process can produce errors, human or
                    software related, that can result in the wrong number of
                    hours being uploaded into Zoho People, or things like
                    duplicate entries. <br />
                    With a click of a button, you can remove all the entries in
                    a month (granted they were not submitted for approval) and
                    start over!
                </Typography>
                <div className="utils-component__section__form">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={"Month to delete"}
                            views={["year", "month"]}
                            value={deleteTlDate}
                            onChange={(value) =>
                                value && setDeleteTlDate(value)
                            }
                            minDate={dayjs("2021-01-01")}
                            maxDate={dayjs()}
                            disabled={deleteTlLoading}
                        />
                    </LocalizationProvider>
                    {roles?.includes(AppRoles.BACKOFFICE) && (
                        <TextField
                            label="User"
                            variant="outlined"
                            value={deleteTlUser}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setDeleteTlUser(event.target.value);
                            }}
                            placeholder="name@principal33.com"
                            type="email"
                            error={
                                deleteTlUser !== "" &&
                                !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(
                                    deleteTlUser,
                                )
                            }
                            disabled={deleteTlLoading}
                        />
                    )}

                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{ alignItems: "center" }}
                    >
                        <Typography>
                            Delete only time logs generated by this tool
                        </Typography>
                        <Switch
                            aria-label="Delete"
                            checked={deleteTlAll}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => setDeleteTlAll(event.target.checked)}
                            disabled={deleteTlLoading}
                            color="secondary"
                        />
                        <Typography>Delete all time logs</Typography>
                    </Stack>
                </div>
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ alignItems: "center", height: "48px" }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleDeleteTl}
                        disabled={deleteTlLoading}
                    >
                        Delete time logs
                    </Button>
                    {deleteTlLoading && <LoadingText />}
                </Stack>
                <div className="utils-component__section__message">
                    {deleteTlMessage}
                </div>
                <Typography variant="caption">
                    <em>
                        Important! Time logs cannot be deleted if they are
                        included in an already-submitted timesheet. To delete
                        the timesheet first, it's essential you contact the
                        Finance Team.
                    </em>
                </Typography>
            </div>
        </div>
    );
}
