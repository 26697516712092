import "src/style/components/SettingsComponent.scss";

import { Button, Typography } from "@mui/material";
import { FC, ReactElement } from "react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import SettingsIcon from "@mui/icons-material/Settings";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

import {
    resetSettings,
    setLightMode,
    setDateFormat,
    setDefaultProject,
    setGroupTimeLogsByDate,
    setHighlightErrors,
    setSkipConfirmation,
    setSkipLandingPage,
    setTheme,
    setAnimateBackground,
} from "src/store/reducers/settingsSlice";
import { themes } from "src/utils/themes";
import SettingsCheckbox from "src/components/SettingsCheckbox";
import SettingsSelect from "src/components/SettingsSelect";
import SettingsToggle from "src/components/SettingsToggle";

const SettingsComponent: FC = (): ReactElement => {
    const dispatch = useAppDispatch();

    const skipConfirmation = useAppSelector(
        (state) => state.settings.skipConfirmation,
    );
    const skipLandingPage = useAppSelector(
        (state) => state.settings.skipLandingPage,
    );
    const defaultProject = useAppSelector(
        (state) => state.settings.defaultProject,
    );
    const highlightErrors = useAppSelector(
        (state) => state.settings.highlightErrors,
    );
    const groupTimeLogsByDate = useAppSelector(
        (state) => state.settings.groupTimeLogsByDate,
    );
    const animateBackground = useAppSelector(
        (state) => state.settings.animateBackground,
    );
    const theme = useAppSelector((state) => state.settings.theme);
    const lightMode = useAppSelector((state) => state.settings.lightMode);
    const dateFormat = useAppSelector((state) => state.settings.dateFormat);

    const projects = useAppSelector((state) => state.user.projects);

    const handleChangeSkipConfirmation = (value: boolean) => {
        dispatch(setSkipConfirmation(value));
    };

    const handleChangeSkipLandingPage = (value: boolean) => {
        dispatch(setSkipLandingPage(value));
    };

    const handleChangeDateFormat = (value: string) => {
        dispatch(setDateFormat(value));
    };

    const handleChangeDefaultProject = (value: string) => {
        dispatch(setDefaultProject(value));
    };

    const handleChangeHighlightErrors = (value: boolean) => {
        dispatch(setHighlightErrors(value));
    };

    const handleChangeGroupTimeLogsByDate = (value: boolean) => {
        dispatch(setGroupTimeLogsByDate(value));
    };

    const handleChangeAnimateBackground = (value: boolean) => {
        dispatch(setAnimateBackground(value));
    };

    const handleChangeTheme = (value: string) => {
        dispatch(setTheme(value));
    };

    const handleChangeLightMode = (value: string) => {
        dispatch(setLightMode(value));
    };

    return (
        <div className="settings-screen">
            <div className="settings-screen__section">
                <Typography
                    fontSize={18}
                    display="block"
                    fontFamily="Syne"
                    fontWeight={700}
                    gutterBottom
                >
                    Time logs
                </Typography>
                <SettingsCheckbox
                    name="Skip Time Logs confirmation"
                    description="If enabled, the app will immediately begin uploading the
                        Time Logs after successful file parsing, granted there
                        are no errors on the Time Logs"
                    value={skipConfirmation}
                    onCheck={handleChangeSkipConfirmation}
                />
                <SettingsSelect
                    name="Default project"
                    description="Select a default project when uploading time logs"
                    value={defaultProject}
                    onSelect={handleChangeDefaultProject}
                    options={Object.assign(
                        {},
                        ...projects.map((p) => ({
                            [p.projectName]: p.projectName,
                        })),
                    )}
                    includeEmptyOption={true}
                />
                <SettingsCheckbox
                    name="Highlight errors and warnings"
                    description="If enabled, time logs with errors or warnings will be
                        highlighted in the time logs table view after parsing."
                    value={highlightErrors}
                    onCheck={handleChangeHighlightErrors}
                />
                <SettingsCheckbox
                    name="Group time logs by date"
                    description="If enabled, time logs will be grouped by work date in
                        the time logs table view after parsing."
                    value={groupTimeLogsByDate}
                    onCheck={handleChangeGroupTimeLogsByDate}
                />
            </div>
            <div className="settings-screen__section">
                <Typography
                    fontSize={18}
                    display="block"
                    fontFamily="Syne"
                    fontWeight={700}
                    gutterBottom
                >
                    App
                </Typography>
                <SettingsCheckbox
                    name="Skip landing page"
                    description="If enabled and logged in, the landing page will be
                        skipped and you will be redirected to the upload screen"
                    value={skipLandingPage}
                    onCheck={handleChangeSkipLandingPage}
                />
                <SettingsSelect
                    name="Date Format"
                    description="Choose how dates are displayed in the app"
                    value={dateFormat}
                    onSelect={handleChangeDateFormat}
                    options={{
                        "ro-RO": "Romanian",
                        "es-ES": "Spanish",
                        "en-US": "English",
                    }}
                    includeEmptyOption={true}
                    emptyOptionLabel="Numeric"
                />
                <SettingsCheckbox
                    name="Animated background"
                    description="Toggle the animated background gradient on or off."
                    value={animateBackground}
                    onCheck={handleChangeAnimateBackground}
                />
                <SettingsSelect
                    name="Theme"
                    description="Select a theme for the app"
                    value={theme}
                    onSelect={handleChangeTheme}
                    options={Object.assign(
                        {},
                        ...Object.keys(themes).map(
                            (t) =>
                                t.toString().indexOf("Dark") === -1 && {
                                    [t]: t.split("Theme")[0],
                                },
                        ),
                    )}
                />
                <SettingsToggle
                    name="Lighting Mode"
                    description="Choose between System Default, Light or Dark Mode"
                    value={lightMode}
                    onToggle={handleChangeLightMode}
                    options={{
                        light: <LightModeIcon />,
                        dark: <DarkModeIcon />,
                    }}
                    includeEmptyOption={true}
                    emptyOptionElement={<SettingsIcon />}
                />
            </div>

            <div>
                <Button
                    variant="contained"
                    onClick={() => dispatch(resetSettings())}
                    color="secondary"
                >
                    Reset Settings
                </Button>
            </div>
        </div>
    );
};

export default SettingsComponent;
