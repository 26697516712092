import "src/style/components/FatalErrorModal.scss";

import { Box, Modal, Typography, useTheme } from "@mui/material";
import { ReactElement } from "react";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { setShowErrorModal } from "src/store/reducers/appSlice";
import MoodBadIcon from "@mui/icons-material/MoodBad";

export default function FatalErrorModal(): ReactElement {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const showErrorModal = useAppSelector((state) => state.app.showErrorModal);

    const handleClose = () => dispatch(setShowErrorModal(false));

    return (
        <Modal open={showErrorModal ? true : false} onClose={handleClose}>
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    textAlign: "center",
                    border: `1px solid ${theme.palette.error.main}`,
                }}
                className="fatal-error-modal"
            >
                <MoodBadIcon color="error" sx={{ fontSize: 72 }} />
                <Typography
                    variant="h4"
                    component="h2"
                    color={theme.palette.error.main}
                    gutterBottom
                    align="center"
                >
                    A fatal error occured...
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    We are terribly sorry about the situation. Our team will
                    work on fixing this!
                </Typography>
            </Box>
        </Modal>
    );
}
