import "src/style/components/TimeLogsTable.scss";

import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useTheme,
} from "@mui/material";
import { FC, ReactElement } from "react";
import { useAppSelector } from "src/hooks/useAppSelector";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { UploadTimeLogDto } from "src/types";
import TimeLogsTableGroupedRow from "src/components/TimeLogsTableGroupedRow";
import { fromToTimeToHoursString } from "src/utils/misc";

interface ITimeLogsTableProps {
    timeLogs: UploadTimeLogDto[];
}

const TimeLogsTable: FC<ITimeLogsTableProps> = ({ timeLogs }): ReactElement => {
    const dateFormat = useAppSelector((state) => state.settings.dateFormat);
    const highlightErrors = useAppSelector(
        (state) => state.settings.highlightErrors,
    );
    const groupTimeLogsByDate = useAppSelector(
        (state) => state.settings.groupTimeLogsByDate,
    );

    const theme = useTheme();

    const getRowStyle = (errorStatus: number): React.CSSProperties => {
        switch (errorStatus) {
            case -1:
                if (theme.palette.mode === "light") {
                    return {
                        backgroundColor: theme.palette.info.light,
                    };
                } else {
                    return {
                        color: theme.palette.info.light,
                    };
                }
            case 1:
                if (theme.palette.mode === "light") {
                    return {
                        backgroundColor: theme.palette.warning.light,
                    };
                } else {
                    return {
                        color: theme.palette.warning.light,
                    };
                }
            case 2:
                if (theme.palette.mode === "light") {
                    return {
                        backgroundColor: theme.palette.error.light,
                    };
                } else {
                    return {
                        color: theme.palette.error.light,
                    };
                }
            default:
                return {};
        }
    };

    const renderTable = (): ReactElement => {
        if (groupTimeLogsByDate) {
            const groupedByDate = Object.groupBy(
                timeLogs,
                ({ timeLogData }) => timeLogData.workDate,
            );

            return (
                <>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Date</TableCell>
                            <TableCell>Total&nbsp;Hours</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="fill-expand">
                        {Object.entries(groupedByDate).map(([key, value]) => (
                            <TimeLogsTableGroupedRow
                                key={key}
                                row={[key, value!]}
                                highlightErrors={highlightErrors}
                                rowStyleFn={getRowStyle}
                            />
                        ))}
                    </TableBody>
                </>
            );
        } else {
            return (
                <>
                    <TableHead>
                        <TableRow>
                            <TableCell>Row</TableCell>
                            <TableCell align="right">Date</TableCell>
                            <TableCell align="right">Job</TableCell>
                            <TableCell align="right">Work&nbsp;item</TableCell>
                            <TableCell align="right">Hours</TableCell>
                            <TableCell align="right">Errors</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="fill-expand">
                        {timeLogs.map((t) => (
                            <TableRow
                                key={t.row}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                    ...(highlightErrors
                                        ? getRowStyle(t.errorStatus)
                                        : undefined),
                                }}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: 16,
                                        fontFamily: "Syne",
                                        ...(highlightErrors
                                            ? getRowStyle(t.errorStatus)
                                            : undefined),
                                    }}
                                >
                                    {t.row}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={
                                        highlightErrors
                                            ? highlightErrors
                                                ? getRowStyle(t.errorStatus)
                                                : undefined
                                            : undefined
                                    }
                                >
                                    {new Date(
                                        t.timeLogData.workDate,
                                    ).toLocaleDateString(
                                        dateFormat === ""
                                            ? undefined
                                            : dateFormat,
                                        {
                                            day: "numeric",
                                            month:
                                                dateFormat === ""
                                                    ? "numeric"
                                                    : "long",
                                            year: "numeric",
                                        },
                                    )}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={
                                        highlightErrors
                                            ? getRowStyle(t.errorStatus)
                                            : undefined
                                    }
                                >
                                    {t.timeLogData.jobName}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={
                                        highlightErrors
                                            ? getRowStyle(t.errorStatus)
                                            : undefined
                                    }
                                >
                                    {t.timeLogData.workItem}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={
                                        highlightErrors
                                            ? getRowStyle(t.errorStatus)
                                            : undefined
                                    }
                                >
                                    {t.timeLogData.hours
                                        ? t.timeLogData.hours
                                        : t.timeLogData.fromTime &&
                                          t.timeLogData.toTime &&
                                          fromToTimeToHoursString(
                                              t.timeLogData.fromTime!,
                                              t.timeLogData.toTime!,
                                          )}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={
                                        highlightErrors
                                            ? getRowStyle(t.errorStatus)
                                            : undefined
                                    }
                                >
                                    <ul>
                                        {t.errors.map((e, index) => (
                                            <li key={index}>
                                                {e.message}
                                                {e.metadata &&
                                                    e.metadata.map((em, i) => (
                                                        <IconButton
                                                            key={i}
                                                            aria-label="Open record"
                                                            color="secondary"
                                                            size="small"
                                                        >
                                                            <a
                                                                href={
                                                                    em.recordUrl
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <OpenInNewIcon />
                                                            </a>
                                                        </IconButton>
                                                    ))}
                                            </li>
                                        ))}
                                    </ul>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </>
            );
        }
    };

    return (
        <TableContainer className="table__container" component={Paper}>
            <Table className="table__table" stickyHeader>
                {renderTable()}
            </Table>
        </TableContainer>
    );
};

export default TimeLogsTable;
