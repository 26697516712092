import { createTheme } from "@mui/material";
import "@mui/material/styles";
import { ThemeDictionary } from "src/types";

const C_EERIE_BLACK = "#121628";
const C_BLUE_GREEN = "#00a6c7";
const C_OCEANBOAT_BLUE = "#007ac1";
const C_JACARTA = "#323755";
const C_ARYLIDE_YELLOW = "#e6cc5e";
const C_BLUE_YONDER = "#4a6fa8";
const C_GAINSBORO = "#DBDDE9";

// for custom colors
declare module "@mui/material/styles" {
    interface Theme {
        status: {
            fatal: string;
        };
        logo: string;
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            fatal?: string;
        };
        logo?: string;
    }
    interface Palette {
        accent: Palette["primary"];
        mediumGray: Palette["primary"];
        darkGray: Palette["primary"];
        logoSecondary: Palette["primary"];
        gradientBg: Palette["primary"];
    }

    interface PaletteOptions {
        accent?: PaletteOptions["primary"];
        mediumGray?: PaletteOptions["primary"];
        darkGray?: PaletteOptions["primary"];
        logoSecondary?: PaletteOptions["primary"];
        gradientBg?: PaletteOptions["primary"];
    }
}

const initTheme = createTheme();

const baseTheme = createTheme({
    palette: {
        error: {
            main: "#FF0000",
            contrastText: "#fff",
        },
        success: {
            main: "#27E028",
            contrastText: "#fff",
        },
        warning: {
            main: "#ff7f11",
        },
        accent: initTheme.palette.augmentColor({
            color: { main: C_BLUE_YONDER },
        }),
        darkGray: initTheme.palette.augmentColor({
            color: { main: "#707070" },
        }),
        mediumGray: initTheme.palette.augmentColor({
            color: { main: "#b5b5b5" },
        }),
        logoSecondary: initTheme.palette.augmentColor({
            color: { main: C_EERIE_BLACK },
        }),
        gradientBg: initTheme.palette.augmentColor({
            color: { main: "#f7f7f7" },
        }),
        tonalOffset: {
            light: 0.8,
            dark: 0.2,
        },
    },
    status: {
        fatal: "#610e0e",
    },
    shape: {
        borderRadius: 5,
    },
    logo: "logo-white.svg",
    typography: {
        htmlFontSize: 20,
        fontSize: 16,
        fontFamily: [
            "Inter",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        h1: {
            fontFamily: "Syne",
            fontWeight: 600,
        },
        h2: {
            fontFamily: "Syne",
            fontWeight: 600,
        },
        h3: {
            fontFamily: "Syne",
            fontWeight: 600,
        },
        h4: {
            fontFamily: "Syne",
            fontWeight: 600,
        },
        h5: {
            fontFamily: "Syne",
            fontWeight: 600,
        },
        button: {
            textTransform: "none",
            textDecoration: "none",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "4px",
                    textTransform: "lowercase",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: 18,
                    fontFamily: "Syne",
                    fontWeight: 600,
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fill: "currentColor",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: "inherit",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "inherit",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: "inherit",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiInputAdornment-root": {
                        color: "inherit",
                    },
                    "& .MuiButtonBase-root": {
                        color: "inherit",
                    },
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    color: "inherit",
                    borderColor: "inherit",
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: "inherit",
                    "&.Mui-selected": {
                        borderColor: "inherit",
                    },
                },
            },
        },
    },
});

const defaultTheme = createTheme({
    ...baseTheme,
    palette: {
        ...baseTheme.palette,
        mode: "light",
        primary: {
            main: C_EERIE_BLACK,
        },
        secondary: {
            main: C_BLUE_GREEN,
            contrastText: "#ffffff",
        },
        accent: baseTheme.palette.augmentColor({
            color: { main: C_BLUE_YONDER },
        }),
        logoSecondary: initTheme.palette.augmentColor({
            color: { main: C_BLUE_GREEN },
        }),
        background: {
            default: "#f7f7f7",
        },
        text: {
            primary: C_EERIE_BLACK,
        },
    },
});

const defaultThemeDark = createTheme({
    ...baseTheme,
    palette: {
        ...baseTheme.palette,
        mode: "dark",
        primary: {
            main: C_BLUE_GREEN,
            contrastText: "#ffffff",
        },
        secondary: {
            main: C_BLUE_GREEN,
            contrastText: "#ffffff",
        },
        accent: baseTheme.palette.augmentColor({
            color: { main: C_BLUE_YONDER },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_BLUE_GREEN },
        }),
        gradientBg: initTheme.palette.augmentColor({
            color: { main: "#121212" },
        }),
        background: {
            default: "#121212",
            paper: "#121212",
        },
        text: {
            primary: "#ffffff",
        },
    },
});

const eerieBlackTheme = createTheme({
    ...defaultTheme,
    logo: "logo-white-full.svg",
    palette: {
        ...defaultTheme.palette,
        primary: {
            main: C_EERIE_BLACK,
            contrastText: "#ffffff",
        },
        secondary: {
            main: C_JACARTA,
            contrastText: "#ffffff",
        },
        accent: defaultTheme.palette.augmentColor({
            color: { main: C_EERIE_BLACK },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: "#ffffff" },
        }),
    },
});

const eerieBlackThemeDark = createTheme({
    ...defaultThemeDark,
    logo: "logo-white-full.svg",
    palette: {
        ...defaultThemeDark.palette,
        primary: {
            main: C_JACARTA,
            contrastText: "#ffffff",
        },
        secondary: {
            main: C_JACARTA,
            contrastText: "#ffffff",
        },
        accent: defaultThemeDark.palette.augmentColor({
            color: { main: C_EERIE_BLACK },
        }),
        gradientBg: defaultTheme.palette.augmentColor({
            color: { main: C_EERIE_BLACK },
        }),
        background: {
            default: C_EERIE_BLACK,
            paper: C_EERIE_BLACK,
        },
    },
});

const blueGreenTheme = createTheme({
    ...defaultTheme,
    logo: "logo-white.svg",
    palette: {
        ...defaultTheme.palette,
        mode: "light",
        primary: {
            main: C_BLUE_GREEN,
            contrastText: "#ffffff",
        },
        secondary: {
            main: C_BLUE_GREEN,
            contrastText: "#ffffff",
        },
        accent: defaultTheme.palette.augmentColor({
            color: { main: C_OCEANBOAT_BLUE },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_OCEANBOAT_BLUE },
        }),
    },
});

const blueGreenThemeDark = createTheme({
    ...defaultThemeDark,
    palette: {
        ...defaultThemeDark.palette,
        primary: {
            main: C_BLUE_GREEN,
            contrastText: "#ffffff",
        },
        secondary: {
            main: C_BLUE_GREEN,
            contrastText: "#ffffff",
        },
        accent: baseTheme.palette.augmentColor({
            color: { main: C_BLUE_GREEN },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_OCEANBOAT_BLUE },
        }),
    },
});

const oceanBoatTheme = createTheme({
    ...defaultTheme,
    logo: "logo-white.svg",
    palette: {
        ...defaultTheme.palette,
        primary: {
            main: C_OCEANBOAT_BLUE,
            contrastText: "#ffffff",
        },
        secondary: {
            main: C_OCEANBOAT_BLUE,
            contrastText: "#ffffff",
        },
        accent: defaultTheme.palette.augmentColor({
            color: { main: C_BLUE_GREEN },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_BLUE_GREEN },
        }),
    },
});

const oceanBoatThemeDark = createTheme({
    ...defaultThemeDark,
    palette: {
        ...defaultThemeDark.palette,
        mode: "dark",
        primary: {
            main: C_OCEANBOAT_BLUE,
            contrastText: "#ffffff",
        },
        secondary: {
            main: C_OCEANBOAT_BLUE,
            contrastText: "#ffffff",
        },
        accent: baseTheme.palette.augmentColor({
            color: { main: C_OCEANBOAT_BLUE },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_BLUE_GREEN },
        }),
    },
});

const jacartaTheme = createTheme({
    ...defaultTheme,
    logo: "logo-white-full.svg",
    palette: {
        ...defaultTheme.palette,
        primary: {
            main: C_JACARTA,
            contrastText: "#ffffff",
        },
        secondary: {
            main: C_EERIE_BLACK,
            contrastText: "#ffffff",
        },
        accent: defaultTheme.palette.augmentColor({
            color: { main: C_OCEANBOAT_BLUE },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_OCEANBOAT_BLUE },
        }),
        text: {
            primary: C_JACARTA,
        },
    },
});

const jacartaThemeDark = createTheme({
    ...defaultThemeDark,
    logo: "logo-white-full.svg",
    palette: {
        ...defaultThemeDark.palette,
        primary: {
            main: C_OCEANBOAT_BLUE,
            contrastText: "#ffffff",
        },
        secondary: {
            main: C_OCEANBOAT_BLUE,
            contrastText: "#ffffff",
        },
        accent: defaultThemeDark.palette.augmentColor({
            color: { main: C_EERIE_BLACK },
        }),
        gradientBg: defaultTheme.palette.augmentColor({
            color: { main: C_EERIE_BLACK },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_OCEANBOAT_BLUE },
        }),
        background: {
            default: C_JACARTA,
            paper: C_JACARTA,
        },
    },
});

const arylideYellowTheme = createTheme({
    ...defaultTheme,
    logo: "logo-dark.svg",
    palette: {
        ...defaultTheme.palette,
        primary: {
            main: C_ARYLIDE_YELLOW,
            contrastText: C_EERIE_BLACK,
        },
        secondary: {
            main: C_EERIE_BLACK,
            contrastText: "#ffffff",
        },
        accent: defaultTheme.palette.augmentColor({
            color: { main: C_JACARTA },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_EERIE_BLACK },
        }),
    },
});

const arylideYellowThemeDark = createTheme({
    ...defaultThemeDark,
    logo: "logo-white.svg",
    palette: {
        ...defaultThemeDark.palette,
        primary: {
            main: C_ARYLIDE_YELLOW,
            contrastText: "#ffffff",
        },
        secondary: {
            main: C_ARYLIDE_YELLOW,
            contrastText: "#ffffff",
        },
        accent: defaultThemeDark.palette.augmentColor({
            color: { main: C_JACARTA },
        }),
        gradientBg: defaultTheme.palette.augmentColor({
            color: { main: C_EERIE_BLACK },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_EERIE_BLACK },
        }),
        background: {
            default: C_EERIE_BLACK,
            paper: C_EERIE_BLACK,
        },
    },
});

const blueYonderTheme = createTheme({
    ...defaultTheme,
    logo: "logo-white.svg",
    palette: {
        ...defaultTheme.palette,
        primary: {
            main: C_BLUE_YONDER,
            contrastText: "#ffffff",
        },
        secondary: {
            main: C_OCEANBOAT_BLUE,
            contrastText: "#ffffff",
        },
        accent: defaultTheme.palette.augmentColor({
            color: { main: C_BLUE_GREEN },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_OCEANBOAT_BLUE },
        }),
    },
});

const blueYonderThemeDark = createTheme({
    ...defaultThemeDark,
    palette: {
        ...defaultThemeDark.palette,
        mode: "dark",
        primary: {
            main: C_BLUE_YONDER,
            contrastText: "#ffffff",
        },
        secondary: {
            main: C_BLUE_YONDER,
            contrastText: "#ffffff",
        },
        accent: baseTheme.palette.augmentColor({
            color: { main: C_JACARTA },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_OCEANBOAT_BLUE },
        }),
        text: {
            primary: "#ffffff",
        },
        background: {
            default: "#121212",
        },
    },
});

const gainsboroTheme = createTheme({
    ...defaultTheme,
    logo: "logo.svg",
    palette: {
        ...defaultTheme.palette,
        primary: {
            main: C_GAINSBORO,
        },
        secondary: {
            main: C_JACARTA,
            contrastText: "#ffffff",
        },
        accent: defaultTheme.palette.augmentColor({
            color: { main: C_BLUE_YONDER },
        }),
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_JACARTA },
        }),
    },
});

const gainsboroThemeDark = createTheme({
    ...defaultThemeDark,
    palette: {
        ...defaultThemeDark.palette,
        primary: {
            main: C_GAINSBORO,
        },
        secondary: {
            main: C_GAINSBORO,
            contrastText: "#ffffff",
        },
        accent: defaultThemeDark.palette.augmentColor({
            color: { main: C_BLUE_YONDER },
        }),
        text: {
            primary: C_GAINSBORO,
        },
        background: {
            default: "#121212",
        },
        logoSecondary: defaultTheme.palette.augmentColor({
            color: { main: C_JACARTA },
        }),
    },
});

const themes: ThemeDictionary = {
    defaultTheme,
    defaultThemeDark,
    eerieBlackTheme,
    eerieBlackThemeDark,
    blueGreenTheme,
    blueGreenThemeDark,
    oceanBoatTheme,
    oceanBoatThemeDark,
    jacartaTheme,
    jacartaThemeDark,
    arylideYellowTheme,
    arylideYellowThemeDark,
    blueYonderTheme,
    blueYonderThemeDark,
    gainsboroTheme,
    gainsboroThemeDark,
};

export { themes };
