import { configureStore, combineReducers, Action } from "@reduxjs/toolkit";

import appReducer, {
    clearErrors,
    clearFileNames,
    clearTimeLogs,
    setActiveStep,
    setBlobUrl,
    setProgressStage,
    setTimesheetId,
} from "src/store/reducers/appSlice";
import userReducer from "src/store/reducers/userSlice";
import authReducer from "src/store/reducers/authSlice";
import settingsReducer from "src/store/reducers/settingsSlice";
import { ProgressStage } from "src/types";
import { loadState } from "src/utils/storage";

const combinedReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    auth: authReducer,
    settings: settingsReducer,
});

const rootReducer = (
    state: ReturnType<typeof combinedReducer> | undefined,
    action: Action,
) => {
    if (action.type === "reset") {
        state = undefined;
    }
    return combinedReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
    preloadedState: loadState(),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const resetTimeLogsRelatedSlices = () => {
    return (dispatch: AppDispatch) => {
        dispatch(clearTimeLogs());
        dispatch(clearErrors());
        dispatch(clearFileNames());
        dispatch(setProgressStage(ProgressStage.INITIAL));
        dispatch(setActiveStep(0));
        dispatch(setBlobUrl(""));
        dispatch(setTimesheetId(""));
    };
};
