import "src/style/components/HandleIssuesComponent.scss";

import { ReactElement, useEffect } from "react";
import TimeLogsTable from "src/components/TimeLogsTable";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { setProgressStage } from "src/store/reducers/appSlice";
import { ProgressStage, UploadTimeLogDto } from "src/types";
import { useTheme } from "@mui/material";
import { decimalHourToString, getTimeLogTotalHours } from "src/utils/misc";

export default function HandleIssuesComponent(): ReactElement {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const timeLogs = useAppSelector((state) => state.app.timeLogs);

    useEffect(() => {
        dispatch(setProgressStage(ProgressStage.VALIDATE_TIMELOGS));
    }, []);

    const getTimeLogsWithErrorStatus = (es: number): UploadTimeLogDto[] => {
        return timeLogs.filter((t) => t.errorStatus === es);
    };

    return (
        <div className="handle-issues">
            <div className="handle-issues__numbers">
                <span>
                    Total entries: <strong>{timeLogs.length}</strong>
                </span>
                <span>
                    With Warnings:{" "}
                    <strong style={{ color: theme.palette.warning.main }}>
                        {getTimeLogsWithErrorStatus(1).length}
                    </strong>
                </span>
                <span>
                    With Errors:{" "}
                    <strong style={{ color: theme.palette.error.main }}>
                        {getTimeLogsWithErrorStatus(2).length}
                    </strong>
                </span>
                <span>
                    Total hours:{" "}
                    <strong>
                        {decimalHourToString(getTimeLogTotalHours(timeLogs))}
                    </strong>
                </span>
                <span>
                    Accepted hours:{" "}
                    <strong>
                        {decimalHourToString(
                            getTimeLogTotalHours(timeLogs) -
                                getTimeLogTotalHours(
                                    getTimeLogsWithErrorStatus(2),
                                ),
                        )}
                    </strong>
                </span>
            </div>
            <TimeLogsTable timeLogs={timeLogs} />
        </div>
    );
}
