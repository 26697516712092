import { useIsAuthenticated } from "@azure/msal-react";
import { FC, ReactElement } from "react";
import { Navigate } from "react-router-dom";

interface IGuardedRouteProps {
    children: ReactElement;
}

const GuardedRoute: FC<IGuardedRouteProps> = ({ children }): ReactElement => {
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated === true) {
        console.log("Currently authenticated judging by MSAL 👍");
        return children;
    } else {
        console.log(
            "Oops.. not authenticated enought to visit the route! Redirect to /login ✋",
        );
        return <Navigate to="/login" />;
    }
};

export default GuardedRoute;
