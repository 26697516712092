import { useEffect, ReactElement, FC } from "react";
import "src/style/LoginPage.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, CircularProgress, Paper, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import {
    useIsAuthenticated,
    useMsal,
    useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import {
    authRequest,
    zohoAcquireTokenSilent,
    zohoAuthRequest,
    zohoTokenRequest,
} from "src/utils/auth";
import {
    resetAuthSlice,
    setAccessToken,
    setCreationTime,
    setExpiresIn,
    setRefreshToken,
} from "src/store/reducers/authSlice";
import { useAppSelector } from "src/hooks/useAppSelector";

const LoginPage: FC = (): ReactElement => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { login, result, error } = useMsalAuthentication(
        InteractionType.Silent,
        authRequest,
    );

    const zohoAccessToken = useAppSelector((state) => state.auth.access_token);
    const zohoRefreshToken = useAppSelector(
        (state) => state.auth.refresh_token,
    );

    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const clearTokensParam = searchParams.get("clearTokens");
    const accountsServer = searchParams.get("accounts-server");
    const zohoAuthError = searchParams.get("error");

    const handleZohoToken = async () => {
        await zohoTokenRequest(code as string).then((result) => {
            dispatch(setAccessToken(result!.access_token));
            dispatch(setRefreshToken(result!.refresh_token));
            dispatch(setCreationTime(new Date().getTime()));
            dispatch(setExpiresIn(result!.expires_in));
        });
    };

    const cancelLogin = (): void => {
        instance.logoutRedirect();
    };

    // logged into both M and Z
    useEffect(() => {
        if (isAuthenticated && zohoAccessToken) {
            navigate("/home");
        }
    }, [isAuthenticated, zohoAccessToken]);

    // not logged in -> error
    useEffect(() => {
        if (error) {
            login(InteractionType.Redirect, authRequest).then((result) => {
                console.log("Runs in error hook");
                instance.setActiveAccount(result!.account);
            });
        }
    }, [error]);

    // returned from M login
    useEffect(() => {
        if (result != null) {
            console.log("Runs in result hook");

            instance.setActiveAccount(result!.account);
            console.log("Active Microsoft account set 😁");
            if (!code && !zohoAccessToken) {
                console.log(
                    "Zoho Access Token not found, Code not found either 🤷‍♀️",
                );

                if (!zohoRefreshToken) {
                    console.log("Authenticate in Zoho 👉");
                    zohoAuthRequest();
                } else {
                    console.log(
                        "Refresh token present! Get a new access token 👌",
                    );
                    zohoAcquireTokenSilent().then(() => navigate("/home"));
                }
            }
        }
    }, [result]);

    // returned from Z login
    useEffect(() => {
        if (clearTokensParam) {
            dispatch(resetAuthSlice());
        }
        if (code) {
            if (accountsServer === "https://accounts.zoho.eu") {
                handleZohoToken().then(() => {
                    navigate("/home");
                });
            }
        }
    }, []);

    return (
        <div className="login-page">
            {!zohoAuthError ? (
                <div className="spinner-container">
                    <CircularProgress
                        variant="indeterminate"
                        color="secondary"
                        size={100}
                        thickness={5}
                    />
                </div>
            ) : (
                <Paper sx={{ padding: 5 }}>
                    <Typography variant="body1" gutterBottom>
                        There was an error logging you into Zoho Accounts:
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ color: "red" }}
                        gutterBottom
                    >
                        {zohoAuthError}
                    </Typography>
                    <div className="error__button-row">
                        <Button
                            variant="contained"
                            onClick={() => zohoAuthRequest()}
                            color="secondary"
                        >
                            try again
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => cancelLogin()}
                        >
                            cancel
                        </Button>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default LoginPage;
