import "src/style/HomePage.scss";
import React, { FC, ReactElement, useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Tab,
    Tabs,
    Typography,
    useTheme,
} from "@mui/material";
import CustomTabPanel, { a11yProps } from "src/components/CustomTabPanel";
import { resetTimeLogsRelatedSlices } from "src/store/store";
import ProjectComponent from "src/components/ProjectComponent";
import { fetchWithAuth, getTarget } from "src/utils/api";
import {
    ApiError,
    ApiErrorSeverity,
    AppRoles,
    AspMvcErrorResponse,
    EmployeeDto,
    ProgressStage,
    Project,
    SummaryResult,
    UploadTimeLogDto,
} from "src/types";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import DragAndDrop from "src/components/DragAndDrop";
import { getRoles } from "src/utils/auth";
import {
    setActiveStep,
    setBlobUrl,
    setDataFetchedTime,
    setErrors,
    setFileNames,
    setProgressStage,
    setTimeLogs,
} from "src/store/reducers/appSlice";
import SettingsComponent from "src/components/SettingsComponent";
import UploadTimeLogsComponent from "src/components/UploadTimeLogsComponent";
import HandleIssuesComponent from "src/components/HandleIssuesComponent";
import SubmitTimesheetComponent from "src/components/SubmitTimesheetComponent";
import FinishedComponent from "src/components/FinishedComponent";
import { useMsal } from "@azure/msal-react";
import ExportTimesheetsComponent from "src/components/ExportTimesheetsComponent";
import UtilsComponent from "src/components/UtilsComponent";
import {
    setEmployee,
    setProjects,
    setSelectedProject,
} from "src/store/reducers/userSlice";
import { setDefaultProject } from "src/store/reducers/settingsSlice";

interface IUploadStep {
    label: string;
    element: JSX.Element;
    optional?: boolean;
    hideNext?: boolean;
}

const ALLOWED_EXTENSIONS: string[] = ["xlsx", "csv"];

const HomePage: FC = (): ReactElement => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { instance } = useMsal();

    const progressStage = useAppSelector((state) => state.app.progressStage);
    const activeStep = useAppSelector((state) => state.app.activeStep);
    const employee = useAppSelector((state) => state.user.employee);
    const selectedProject = useAppSelector(
        (state) => state.user.selectedProject,
    );
    const defaultProject = useAppSelector(
        (state) => state.settings.defaultProject,
    );
    const dataFetchedTime = useAppSelector(
        (state) => state.app.dataFetchedTime,
    );
    const projects = useAppSelector((state) => state.user.projects);
    const skipConfirmation = useAppSelector(
        (state) => state.settings.skipConfirmation,
    );

    // variables local
    const [roles] = useState(getRoles(instance));
    const [skipped, setSkipped] = React.useState(new Set<number>());

    // loading
    const [loadingDragAndDrop, setLoadingDragAndDrop] = useState(false);

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        dispatch(setActiveStep(activeStep + 1));
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        // back to step 1 if upload already happened
        if (progressStage >= 10) {
            dispatch(setActiveStep(0));
        } else {
            dispatch(setActiveStep(activeStep - 1));
        }
    };

    const handleSkip = () => {
        if (!steps[activeStep].optional) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        dispatch(setActiveStep(activeStep + 1));
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        dispatch(setActiveStep(0));
    };

    const advanceStep = (to?: number): void => {
        if (to) {
            dispatch(setActiveStep(to));
        } else {
            dispatch(setActiveStep(activeStep + 1));
        }
    };

    const handleDrop = async (file: File): Promise<void> => {
        setLoadingDragAndDrop(true);
        const uploadTimesheetFormData = new FormData();
        uploadTimesheetFormData.append(
            "ProjectId",
            selectedProject?.projectId as string,
        );
        uploadTimesheetFormData.append("File", file);
        uploadTimesheetFormData.append("Employee", JSON.stringify(employee));

        const request = new Request(getTarget() + "/api/upload/validate", {
            method: "post",
            body: uploadTimesheetFormData,
        });
        await fetchWithAuth<Array<UploadTimeLogDto>>(request)
            .then((value) => {
                dispatch(setTimeLogs(value));

                dispatch(setFileNames([file.name]));
                // create and set blob
                const blob = new Blob([file], { type: file.type });
                const url = URL.createObjectURL(blob);

                dispatch(setBlobUrl(url));

                if (
                    skipConfirmation &&
                    value.every((t) => t.errorStatus === 0)
                ) {
                    dispatch(setActiveStep(2));
                } else {
                    advanceStep();
                }
            })
            .catch(async (e: Response) => {
                // file validation error
                if (e.status === 400) {
                    const eResponse: AspMvcErrorResponse = await e.json();

                    const _errors: ApiError[] = eResponse.errors!["file"].map(
                        (s) => ({
                            message: s,
                            severity: ApiErrorSeverity.File,
                        }),
                    );

                    dispatch(setErrors(_errors));
                }
            })
            .finally(() => {
                setLoadingDragAndDrop(false);
            });
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const shouldShowLoading = (): boolean => {
        return progressStage === -99;
    };

    const disablePreviousStep = (): boolean => {
        return activeStep === 0 || progressStage === ProgressStage.UPLOADING;
    };

    const disableNextStep = (): boolean => {
        return (
            steps[activeStep].hideNext ||
            progressStage === ProgressStage.UPLOADING ||
            progressStage === ProgressStage.UPLOADED_ERRORS ||
            // use manually confirm button
            progressStage === ProgressStage.TIMELOG_ERRORS ||
            progressStage === ProgressStage.UPLOADED_NO_SUBMIT
        );
    };

    const fetchData = async () => {
        dispatch(setProgressStage(-99));
        await fetchWithAuth<EmployeeDto>(
            getTarget() + "/api/ZohoUser/employee",
        ).then(async (_employee) => {
            dispatch(setEmployee(_employee));
            dispatch(setProgressStage(0));
            const request = new Request(getTarget() + "/api/ZohoUser/summary", {
                method: "post",
                body: JSON.stringify(_employee),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            });
            await fetchWithAuth<SummaryResult>(request).then((_summary) => {
                if (_summary.projects) {
                    dispatch(setProjects(_summary.projects));
                    handleDefaultProject(_summary.projects);
                }

                dispatch(setDataFetchedTime(new Date().getTime()));
            });
        });
    };

    const handleDefaultProject = (projects: Project[]) => {
        if (projects.length > 0) {
            if (defaultProject !== "") {
                const defP: Project = projects.filter(
                    (p) => p.projectName === defaultProject,
                )[0];
                if (defP) {
                    dispatch(setSelectedProject(defP));
                    dispatch(setProgressStage(0));
                } else {
                    // default project no longer assigned to user
                    dispatch(setDefaultProject(""));
                    dispatch(setSelectedProject(projects[0]));
                    dispatch(setProgressStage(0));
                }
            } else {
                if (projects?.length > 1) {
                    dispatch(setSelectedProject(null));
                } else {
                    dispatch(setSelectedProject(projects[0]));
                    dispatch(setProgressStage(0));
                }
            }
        } else {
            dispatch(setSelectedProject(null));
            if (defaultProject !== "") {
                dispatch(setDefaultProject(""));
            }
            dispatch(
                setProgressStage(ProgressStage.DISABLED_NO_PROJECTS_ASSIGNED),
            );
        }
    };

    const steps: IUploadStep[] = [
        {
            label: "Select files",
            hideNext: true,
            element: (
                <div className="step--column upload__step1">
                    <ProjectComponent />
                    {shouldShowLoading() ? (
                        <div className="circular-loading">
                            <CircularProgress
                                variant="indeterminate"
                                color="secondary"
                                size={70}
                                thickness={5}
                            />
                        </div>
                    ) : (
                        <DragAndDrop
                            allowedExtensions={ALLOWED_EXTENSIONS}
                            isLoading={loadingDragAndDrop}
                            dropHandler={handleDrop}
                        />
                    )}
                </div>
            ),
        },
        {
            // TODO Make label dynamic if no errors occur
            label: "Handle issues",
            element: <HandleIssuesComponent />,
        },
        {
            label: "Upload time logs",
            element: <UploadTimeLogsComponent />,
        },
        {
            label: "Submit timesheet",
            optional: true,
            hideNext: true,
            element: <SubmitTimesheetComponent />,
        },
    ];

    useEffect(() => {
        // projects
        if (
            !dataFetchedTime ||
            dataFetchedTime + 60 * 1000 < new Date().getTime()
        ) {
            fetchData();
        } else {
            handleDefaultProject(projects);
        }

        // stage
        if (
            progressStage === ProgressStage.FINISHED ||
            progressStage === ProgressStage.UPLOADED_NO_SUBMIT ||
            progressStage === ProgressStage.UPLOADED_ERRORS
        ) {
            dispatch(resetTimeLogsRelatedSlices());
        }
    }, []);

    return (
        <div className="home">
            <Paper
                className="home__content"
                elevation={0}
                sx={{ border: "1px solid " + theme.palette.secondary.main }}
            >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Upload file" {...a11yProps(0)} />
                        <Tab label="Settings" {...a11yProps(1)} />
                        <Tab label="Utils" {...a11yProps(2)} />
                        {roles?.includes(AppRoles.BACKOFFICE) && (
                            <Tab label="Export" {...a11yProps(3)} />
                        )}
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    {progressStage !==
                    ProgressStage.DISABLED_NO_PROJECTS_ASSIGNED ? (
                        <>
                            <Stepper activeStep={activeStep}>
                                {steps.map((step, index) => {
                                    const stepProps: { completed?: boolean } =
                                        {};
                                    const labelProps: {
                                        optional?: React.ReactNode;
                                    } = {};
                                    if (step.optional) {
                                        labelProps.optional = (
                                            <Typography variant="caption">
                                                Optional
                                            </Typography>
                                        );
                                    }
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={step.label} {...stepProps}>
                                            <StepLabel {...labelProps}>
                                                {step.label}
                                            </StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {activeStep === steps.length ? (
                                <div className="home__step-wrapper fill-expand">
                                    <FinishedComponent
                                        resetHandler={handleReset}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="home__step-wrapper fill-expand">
                                        {steps[activeStep].element}
                                    </div>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        {activeStep > 0 && (
                                            <Button
                                                disabled={disablePreviousStep()}
                                                onClick={handleBack}
                                                sx={{ mt: 2 }}
                                                variant="outlined"
                                                color="secondary"
                                            >
                                                Back
                                            </Button>
                                        )}
                                        <Box sx={{ flex: "1 1 auto" }} />
                                        {steps[activeStep].optional && (
                                            <Button
                                                color="inherit"
                                                onClick={handleSkip}
                                                sx={{ mr: 1, mt: 2 }}
                                            >
                                                Skip
                                            </Button>
                                        )}
                                        {!steps[activeStep].hideNext && (
                                            <Button
                                                onClick={handleNext}
                                                disabled={disableNextStep()}
                                                variant="outlined"
                                                color="secondary"
                                                sx={{ mt: 2 }}
                                            >
                                                {activeStep === steps.length - 1
                                                    ? "Finish"
                                                    : "Next"}
                                            </Button>
                                        )}
                                    </Box>
                                </>
                            )}
                        </>
                    ) : (
                        <div className="no-projects">
                            {" "}
                            <Typography variant="h2" gutterBottom>
                                Error!
                            </Typography>
                            <Typography
                                variant="h6"
                                gutterBottom
                                align="center"
                            >
                                <span
                                    style={{ color: theme.palette.error.main }}
                                >
                                    None
                                </span>{" "}
                                of your assigned projects are accepted by the
                                tool.
                                <br />
                                If you think this is a mistake, please submit a
                                ticket in the Help Center.
                            </Typography>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                sx={{ marginTop: 3 }}
                            >
                                <a
                                    href="https://helpcenter.principal33.com/portal/en/newticket?category=Zoho%20People&subCategory=Zoho%20update%2Fcorrection"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    create ticket
                                </a>
                            </Button>
                        </div>
                    )}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <SettingsComponent />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <UtilsComponent />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <ExportTimesheetsComponent />
                </CustomTabPanel>
            </Paper>
        </div>
    );
};

export default HomePage;
