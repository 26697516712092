import { NavigateFunction, useNavigate } from "react-router-dom";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AnyAction, Dispatch } from "redux";

interface IOutHook {
    useNavigate?: NavigateFunction;
    useDispatch?: Dispatch<AnyAction>;
}

const OutHook: IOutHook = {};

export const OutHookConfigurator: FC = () => {
    const _useNavigate = useNavigate();
    const _useDispatch = useDispatch();

    useEffect(() => {
        OutHook.useNavigate = _useNavigate;
        OutHook.useDispatch = _useDispatch;
    }, [_useNavigate, _useDispatch]);

    return null;
};

export default OutHook;
