import "src/style/components/ProgressBar.scss";

import { LinearProgress, useTheme } from "@mui/material";
import { FC, ReactElement, useEffect } from "react";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { setProgressCurrent } from "src/store/reducers/appSlice";

/**
 * Wrapper for MUI LinearProgress that is connected to Redux state and has a percentage indicator
 * @returns ReactElement
 */
const ProgressBar: FC = (): ReactElement => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const message = useAppSelector((state) => state.app.progressMessage);
    const currentValue = useAppSelector((state) => state.app.progressCurrent);
    const maximumValue = useAppSelector((state) => state.app.progressMaximum);

    const normalizeValue = (): number => {
        if (
            !currentValue ||
            !maximumValue ||
            currentValue < 0 ||
            maximumValue < 0
        ) {
            return -1;
        }
        return (currentValue * 100) / maximumValue;
    };

    // reset whenever rendered
    useEffect(() => {
        dispatch(setProgressCurrent(-1));
    }, []);

    return (
        <div className="progress-bar-wrapper">
            <div
                className="progress-bar__text"
                style={{ color: theme.palette.darkGray.main }}
            >
                {message}
            </div>
            <LinearProgress
                color="secondary"
                variant={
                    normalizeValue() !== -1 ? "determinate" : "indeterminate"
                }
                value={normalizeValue()}
                className="progress-bar__linear-progress"
            />
            {normalizeValue() !== -1 && (
                <div
                    className="progress-bar__number"
                    style={{ color: theme.palette.secondary.main }}
                >
                    {Math.ceil(normalizeValue())}%
                </div>
            )}
        </div>
    );
};

export default ProgressBar;
