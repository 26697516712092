import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type AuthSliceState = {
    access_token: string | null;
    refresh_token: string | null;
    creation_time: number | null;
    expires_in: number | null;
};

const initialState: AuthSliceState = {
    access_token: null,
    creation_time: null,
    expires_in: null,
    refresh_token: null,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.access_token = action.payload;
        },
        setRefreshToken: (state, action: PayloadAction<string>) => {
            state.refresh_token = action.payload;
        },
        setCreationTime: (state, action: PayloadAction<number>) => {
            state.creation_time = action.payload;
        },
        setExpiresIn: (state, action: PayloadAction<number>) => {
            state.expires_in = action.payload;
        },
        resetAuthSlice: () => {
            return { ...initialState };
        },
    },
});

export const {
    setAccessToken,
    setRefreshToken,
    setCreationTime,
    setExpiresIn,
    resetAuthSlice,
} = authSlice.actions;

export default authSlice.reducer;
