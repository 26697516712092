import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const DEFAULT_THEME = "defaultTheme";

export type SettingsSliceState = {
    skipConfirmation: boolean;
    skipLandingPage: boolean;
    defaultProject: string;
    highlightErrors: boolean;
    groupTimeLogsByDate: boolean;
    animateBackground: boolean;
    theme: string;
    lightMode: string;
    dateFormat: string;
};

const initialSettings: SettingsSliceState = {
    skipConfirmation: false,
    skipLandingPage: false,
    defaultProject: "",
    highlightErrors: true,
    groupTimeLogsByDate: false,
    animateBackground: true,
    theme: DEFAULT_THEME,
    lightMode: "",
    dateFormat: "",
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState: initialSettings,
    reducers: {
        setSkipConfirmation: (state, action: PayloadAction<boolean>) => {
            state.skipConfirmation = action.payload;
        },
        setSkipLandingPage: (state, action: PayloadAction<boolean>) => {
            state.skipLandingPage = action.payload;
        },
        setDefaultProject: (state, action: PayloadAction<string>) => {
            state.defaultProject = action.payload;
        },
        setHighlightErrors: (state, action: PayloadAction<boolean>) => {
            state.highlightErrors = action.payload;
        },
        setGroupTimeLogsByDate: (state, action: PayloadAction<boolean>) => {
            state.groupTimeLogsByDate = action.payload;
        },
        setAnimateBackground: (state, action: PayloadAction<boolean>) => {
            state.animateBackground = action.payload;
        },
        setTheme: (state, action: PayloadAction<string>) => {
            state.theme = action.payload;
        },
        setLightMode: (state, action: PayloadAction<string>) => {
            state.lightMode = action.payload;
        },
        setDateFormat: (state, action: PayloadAction<string>) => {
            state.dateFormat = action.payload;
        },
        resetSettings: () => initialSettings,
    },
});

export const {
    setSkipConfirmation,
    setSkipLandingPage,
    setDefaultProject,
    setHighlightErrors,
    setGroupTimeLogsByDate,
    setAnimateBackground,
    setTheme,
    setLightMode,
    setDateFormat,
    resetSettings,
} = settingsSlice.actions;

export default settingsSlice.reducer;
