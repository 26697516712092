import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    ApiError,
    DeleteInfo,
    ProgressStage,
    UploadTimeLogDto,
} from "src/types";

export type AppSliceState = {
    activeStep: number;
    progressStage: ProgressStage;
    fileNames: string[];
    blobUrl: string;
    timeLogs: UploadTimeLogDto[];
    timesheetId?: string;
    errors: ApiError[];
    deleteInfo?: DeleteInfo;
    progressCurrent: number;
    progressMaximum: number;
    progressMessage: string;
    dataFetchedTime?: number;
    showErrorModal?: boolean;
};

const initialState: AppSliceState = {
    progressStage: ProgressStage.INITIAL,
    fileNames: [],
    activeStep: 0,
    timeLogs: [],
    errors: [],
    blobUrl: "",
    progressCurrent: 0,
    progressMaximum: 0,
    progressMessage: "",
    showErrorModal: false,
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setProgressStage: (state, action: PayloadAction<ProgressStage>) => {
            state.progressStage = action.payload;
        },
        setActiveStep: (state, action: PayloadAction<number>) => {
            state.activeStep = action.payload;
        },
        setFileNames: (state, action: PayloadAction<string[]>) => {
            state.fileNames = action.payload;
        },
        pushFileName: (state, action: PayloadAction<string>) => {
            state.fileNames.push(action.payload);
        },
        setBlobUrl: (state, action: PayloadAction<string>) => {
            state.blobUrl = action.payload;
        },
        clearFileNames: (state) => {
            state.fileNames = [];
        },
        setTimeLogs: (state, action: PayloadAction<UploadTimeLogDto[]>) => {
            state.timeLogs = action.payload;
        },
        clearTimeLogs: (state) => {
            state.timeLogs = [];
        },
        setTimesheetId: (state, action: PayloadAction<string>) => {
            state.timesheetId = action.payload;
        },
        setDeleteInfo: (state, action: PayloadAction<DeleteInfo>) => {
            state.deleteInfo = action.payload;
        },
        setErrors: (state, action: PayloadAction<ApiError[]>) => {
            state.errors = action.payload;
        },
        clearErrors: (state) => {
            state.errors = [];
        },
        pushError: (state, action: PayloadAction<ApiError>) => {
            state.errors = [...state.errors, action.payload];
        },
        setProgressCurrent: (state, action: PayloadAction<number>) => {
            state.progressCurrent = action.payload;
        },
        setProgressMaximum: (state, action: PayloadAction<number>) => {
            state.progressMaximum = action.payload;
        },
        setProgressMessage: (state, action: PayloadAction<string>) => {
            state.progressMessage = action.payload;
        },
        setDataFetchedTime: (state, action: PayloadAction<number>) => {
            state.dataFetchedTime = action.payload;
        },
        setShowErrorModal: (state, action: PayloadAction<boolean>) => {
            state.showErrorModal = action.payload;
        },
        resetAppSlice: () => initialState,
    },
});

export const {
    setProgressStage,
    setActiveStep,
    setFileNames,
    pushFileName,
    clearFileNames,
    setBlobUrl,
    setTimeLogs,
    clearTimeLogs,
    setTimesheetId,
    setDeleteInfo,
    setErrors,
    clearErrors,
    pushError,
    setProgressCurrent,
    setProgressMaximum,
    setProgressMessage,
    setDataFetchedTime,
    setShowErrorModal,
    resetAppSlice,
} = appSlice.actions;

export default appSlice.reducer;
