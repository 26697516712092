import "src/style/components/UploadTimeLogsComponent.scss";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Typography,
    useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ProgressBar from "src/components/ProgressBar";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
    setProgressMessage,
    setProgressStage,
    setTimeLogs,
} from "src/store/reducers/appSlice";
import {
    ConfirmTimeLogsRequest,
    ConfirmTimeLogsResponse,
    ProgressStage,
    UploadTimeLogDto,
} from "src/types";
import { fetchWithAuth, getTarget } from "src/utils/api";
import TimeLogsTable from "src/components/TimeLogsTable";

export default function UploadTimeLogsComponent(): ReactElement {
    const dispatch = useDispatch();
    const theme = useTheme();

    const timeLogs = useAppSelector((state) => state.app.timeLogs);
    const progressStage = useAppSelector((state) => state.app.progressStage);

    const [loading, setLoading] = useState(false);

    const uploadTimeLogs = async (payload: UploadTimeLogDto[]) => {
        setLoading(true);
        dispatch(setProgressStage(ProgressStage.UPLOADING));
        dispatch(setProgressMessage("Uploading time logs"));

        console.log(payload);
        const goodTimeLogs = payload.filter((t) => t.errorStatus < 2);

        console.log(goodTimeLogs);
        const requestBody: ConfirmTimeLogsRequest = {
            timeLogs: goodTimeLogs,
        };

        if (goodTimeLogs.length === 0) {
            return;
        }

        const request = new Request(getTarget() + "/api/upload/confirm", {
            method: "post",
            body: JSON.stringify(requestBody),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        });

        await fetchWithAuth<ConfirmTimeLogsResponse>(request)
            .then((value) => {
                dispatch(setTimeLogs(value.timeLogs));

                // all uploads failed
                if (
                    value.timeLogs.length ===
                    computeErrorTimeLogs(value.timeLogs).length
                ) {
                    dispatch(setProgressStage(ProgressStage.UPLOADED_ERRORS));
                } else {
                    dispatch(setProgressStage(ProgressStage.UPLOADED));
                }

                if (hasSevereError) {
                    dispatch(
                        setProgressStage(ProgressStage.UPLOADED_NO_SUBMIT),
                    );
                }
            })
            // we catch with empty function to avoid raising error
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (computeErrorTimeLogs(timeLogs).length === 0) {
            if (
                progressStage !== ProgressStage.UPLOADED &&
                progressStage !== ProgressStage.UPLOADED_ERRORS &&
                progressStage !== ProgressStage.UPLOADED_NO_SUBMIT
            ) {
                uploadTimeLogs(timeLogs);
            }
        } else {
            if (progressStage !== ProgressStage.UPLOADED_ERRORS) {
                dispatch(setProgressStage(ProgressStage.TIMELOG_ERRORS));
            }
        }
    }, []);

    const countSuccessfulUploads = (input: UploadTimeLogDto[]): number => {
        return input?.filter((t) => t.errorStatus !== 2).length;
    };

    const computeErrorTimeLogs = (
        input: UploadTimeLogDto[],
    ): UploadTimeLogDto[] => {
        return input?.filter((t) => t.errorStatus === 2);
    };

    const [hasSevereError] = useState(
        computeErrorTimeLogs(timeLogs).length > 0,
    );

    return (
        <Box className="upload-timelogs">
            {loading ? (
                <ProgressBar />
            ) : computeErrorTimeLogs(timeLogs).length === 0 ? (
                <div className="upload-timelogs__message">
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="80"
                            height="80"
                            viewBox="0 0 100 100"
                        >
                            <g id="icon_green" transform="translate(-937 -319)">
                                <g
                                    id="Ellipse_6"
                                    data-name="Ellipse 6"
                                    transform="translate(937 319)"
                                    fill="none"
                                    stroke={theme.palette.success.main}
                                    stroke-width="5"
                                >
                                    <circle
                                        cx="50"
                                        cy="50"
                                        r="50"
                                        stroke="none"
                                    />
                                    <circle
                                        cx="50"
                                        cy="50"
                                        r="47.5"
                                        fill="none"
                                    />
                                </g>
                                <path
                                    id="Path_18"
                                    data-name="Path 18"
                                    d="M19.068,0V38.527H0"
                                    transform="translate(993.88 344.153) rotate(45)"
                                    fill="none"
                                    stroke={theme.palette.success.main}
                                    stroke-width="5"
                                />
                            </g>
                        </svg>
                    </div>

                    <Typography variant="h2" gutterBottom>
                        Success!
                    </Typography>
                    <Typography variant="h6">
                        Uploaded{" "}
                        <span style={{ color: theme.palette.success.main }}>
                            {countSuccessfulUploads(timeLogs)}
                        </span>{" "}
                        time logs to Zoho People.
                    </Typography>
                    {computeErrorTimeLogs(timeLogs).length > 0 && (
                        <div className="upload-timelogs__message__however">
                            <Typography
                                variant="h6"
                                gutterBottom
                                align="center"
                            >
                                However,{" "}
                                <span
                                    style={{ color: theme.palette.error.main }}
                                >
                                    {computeErrorTimeLogs(timeLogs).length}
                                </span>{" "}
                                time logs could not be uploaded.
                            </Typography>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    Show error details
                                </AccordionSummary>
                                <AccordionDetails sx={{ overflow: "auto" }}>
                                    <TimeLogsTable
                                        timeLogs={computeErrorTimeLogs(
                                            timeLogs,
                                        )}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    )}
                    {progressStage === ProgressStage.UPLOADED_NO_SUBMIT && (
                        <div className="upload-timelogs__message__however">
                            <Typography
                                variant="h6"
                                gutterBottom
                                align="center"
                            >
                                However,{" "}
                                <span
                                    style={{ color: theme.palette.error.main }}
                                >
                                    you cannot submit a timesheet
                                </span>{" "}
                                in Zoho People.
                            </Typography>
                        </div>
                    )}
                </div>
            ) : (
                <div className="upload-timelogs__message">
                    <Typography variant="h2" gutterBottom>
                        Warning!
                    </Typography>
                    {computeErrorTimeLogs(timeLogs).length !==
                    timeLogs.length ? (
                        <>
                            <Typography
                                variant="h6"
                                gutterBottom
                                align="center"
                            >
                                Some of the generated time logs have{" "}
                                <span
                                    style={{ color: theme.palette.error.main }}
                                >
                                    severe
                                </span>{" "}
                                validation errors.{" "}
                                <span
                                    style={{ color: theme.palette.error.main }}
                                >
                                    {computeErrorTimeLogs(timeLogs).length}
                                </span>{" "}
                                time logs will not be uploaded to Zoho People.
                                <br />
                                In addition, you cannot submit a timesheet into
                                Zoho People from this tool due to the file
                                errors.
                            </Typography>
                            <Button
                                variant="contained"
                                size="large"
                                sx={{ marginTop: 3 }}
                                onClick={() => uploadTimeLogs(timeLogs)}
                                color="secondary"
                            >
                                confirm upload
                            </Button>
                        </>
                    ) : progressStage === ProgressStage.UPLOADED_ERRORS ? (
                        <>
                            <Typography
                                variant="h6"
                                gutterBottom
                                align="center"
                            >
                                <span
                                    style={{ color: theme.palette.error.main }}
                                >
                                    None of the generated time logs could be
                                    uploaded to Zoho People.
                                </span>{" "}
                                Please check the errors below.
                            </Typography>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    Show error details
                                </AccordionSummary>
                                <AccordionDetails sx={{ overflow: "auto" }}>
                                    <TimeLogsTable
                                        timeLogs={computeErrorTimeLogs(
                                            timeLogs,
                                        )}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </>
                    ) : (
                        <>
                            <Typography
                                variant="h6"
                                gutterBottom
                                align="center"
                            >
                                All of the generated time logs have{" "}
                                <span
                                    style={{ color: theme.palette.error.main }}
                                >
                                    severe
                                </span>{" "}
                                validation errors. The upload process will stop
                                here.
                            </Typography>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    Show error details
                                </AccordionSummary>
                                <AccordionDetails sx={{ overflow: "auto" }}>
                                    <TimeLogsTable
                                        timeLogs={computeErrorTimeLogs(
                                            timeLogs,
                                        )}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )}
                </div>
            )}
        </Box>
    );
}
