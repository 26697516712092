import "src/style/components/SettingsSelect.scss";

import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { ReactElement } from "react";

interface ISettingsSelectProps {
    name: string;
    description: string;
    value: string;
    onSelect: (value: string) => void;
    options: { [value: string]: string };
    includeEmptyOption?: boolean;
    emptyOptionLabel?: string;
}

export default function SettingsSelect({
    name,
    description,
    value,
    onSelect,
    options,
    includeEmptyOption,
    emptyOptionLabel,
}: ISettingsSelectProps): ReactElement {
    return (
        <div className="settings-select">
            <Typography
                gutterBottom
                display="block"
                fontSize={16}
                fontWeight={600}
                sx={{ mb: 1 }}
            >
                {name}
            </Typography>
            <div className="settings-select__select">
                <Typography variant="body2">{description}</Typography>
                <Select
                    value={value}
                    onChange={(e: SelectChangeEvent) =>
                        onSelect(e.target.value)
                    }
                >
                    {includeEmptyOption && (
                        <MenuItem value="">
                            {emptyOptionLabel ? emptyOptionLabel : "None"}
                        </MenuItem>
                    )}
                    {Object.keys(options).map((key) => (
                        <MenuItem key={key} value={key}>
                            {options[key]}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </div>
    );
}
