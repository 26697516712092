import "src/style/components/ApiErrorComponent.scss";
import { FC, ReactElement } from "react";
import { ApiError, ApiErrorSeverity } from "src/types";
import { Paper, useTheme } from "@mui/material";

interface IApiErrorComponentProps {
    error: ApiError;
}

const ApiErrorComponent: FC<IApiErrorComponentProps> = ({
    error,
}): ReactElement => {
    const theme = useTheme();

    const computeColor = (): string => {
        switch (error.severity) {
            case ApiErrorSeverity.Severe:
                return theme.status.fatal;
            default:
                return theme.palette.warning.main;
        }
    };

    return (
        <Paper className="error" elevation={8}>
            <div className="error__text">
                <span
                    className="error__severity "
                    style={{ color: computeColor() }}
                >
                    {ApiErrorSeverity[error.severity]} error:{" "}
                </span>
                <div
                    className="error__message"
                    style={{ color: theme.palette.darkGray.main }}
                >
                    {error.message}
                </div>
            </div>
        </Paper>
    );
};

export default ApiErrorComponent;
