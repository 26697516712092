import "src/style/components/FeedbackComponent.scss";

import { ReactElement, useState } from "react";
import { Button, Rating, TextField, Typography, useTheme } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LoadingText from "src/components/LoadingText";
import { fetchWithAuth, getTarget } from "src/utils/api";

export interface IFeedbackComponentProps {
    closeFn: () => void;
}

export default function FeedbackComponent({
    closeFn,
}: IFeedbackComponentProps): ReactElement {
    const theme = useTheme();

    const [rating, setRating] = useState<number | null>(5);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

    const sendFeedback = async (): Promise<void> => {
        setLoading(true);
        const request = new Request(getTarget() + "/api/Feedback", {
            method: "post",
            body: JSON.stringify({
                rating: rating !== null ? rating : 0,
                message,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        });
        await fetchWithAuth(request)
            .then(() => {
                setIsSuccess(true);
            })
            .catch(() => setIsSuccess(false))
            .finally(() => setLoading(false));
    };

    return (
        <form className="feedback">
            <Typography variant="h4" gutterBottom>
                We'd love to hear your opinion!
            </Typography>
            <Typography>
                Do you find the app useful? Are there any things you'd like to
                improve? Please take the time to rate the experience of using
                this tool and aditionally provide some useful feedback!
            </Typography>
            {loading ? (
                <div style={{ margin: "auto" }}>
                    <LoadingText message="Delivering your feedback . . ." />
                </div>
            ) : isSuccess !== null ? (
                <>
                    {isSuccess === true ? (
                        <div>
                            <Typography
                                variant="h5"
                                gutterBottom
                                color={theme.palette.success.main}
                            >
                                Success!
                            </Typography>
                            <Typography variant="body2">
                                Your feedback has been successfully sent!
                            </Typography>
                        </div>
                    ) : (
                        <div>
                            <Typography
                                variant="h5"
                                gutterBottom
                                color={theme.palette.error.main}
                            >
                                An error occured.
                            </Typography>
                            <Typography variant="body2">
                                The feedback could not be sent.
                            </Typography>
                        </div>
                    )}
                    <Button
                        variant="contained"
                        size="large"
                        onClick={closeFn}
                        color="secondary"
                    >
                        close
                    </Button>
                </>
            ) : (
                <>
                    <div className="feedback__fields">
                        <Typography component="legend">Rating</Typography>
                        <Rating
                            value={rating}
                            onChange={(event, newValue) => {
                                setRating(newValue);
                            }}
                            precision={0.5}
                            size="large"
                            icon={
                                <FavoriteIcon
                                    fontSize="inherit"
                                    color="secondary"
                                />
                            }
                            emptyIcon={
                                <FavoriteBorderIcon fontSize="inherit" />
                            }
                        />
                        <TextField
                            value={message}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setMessage(event.target.value);
                            }}
                            multiline
                            fullWidth
                            maxRows={4}
                            label="Your message"
                        />
                    </div>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={sendFeedback}
                        color="secondary"
                    >
                        send
                    </Button>
                </>
            )}
        </form>
    );
}
