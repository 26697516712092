import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmployeeDto, Project } from "src/types";

export type UserSliceState = {
    employee: EmployeeDto | null;
    projects: Project[];
    selectedProject: Project | null;
};

const initialState: UserSliceState = {
    employee: null,
    selectedProject: null,
    projects: [],
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setEmployee: (state, action: PayloadAction<EmployeeDto>) => {
            state.employee = action.payload;
        },
        setProjects: (state, action: PayloadAction<Project[]>) => {
            state.projects = action.payload;
        },
        setSelectedProject: (state, action: PayloadAction<Project | null>) => {
            state.selectedProject = action.payload;
        },
        resetUserSlice: () => initialState,
    },
});

export const { setEmployee, setProjects, setSelectedProject, resetUserSlice } =
    userSlice.actions;

export default userSlice.reducer;
