import { ApiError, ApiErrorSeverity } from "src/types";
import { authRequest, zohoAcquireTokenSilent } from "src/utils/auth";
import store from "src/store/store";
import {
    clearErrors,
    setErrors,
    setShowErrorModal,
} from "src/store/reducers/appSlice";
import { msalInstance } from "src/index";

export const getTarget = (): string => {
    // use empty string if in production (same server)
    if (process.env.ASPNETCORE_URLS === "") {
        return "";
    }
    if (process.env.ASPNETCORE_URLS) {
        return process.env.ASPNETCORE_URLS.split(";")[0];
    }
    if (process.env.ASPNETCORE_HTTPS_PORT) {
        return "https://localhost:" + process.env.ASPNETCORE_HTTPS_PORT;
    }
    // default http port
    return "http://localhost:7046";
};

export const fetchWithAuth = async <T>(request: RequestInfo): Promise<T> => {
    const authResponse = await msalInstance.acquireTokenSilent(authRequest);
    const accessToken = authResponse.accessToken;

    console.log("Getting Zoho token...");
    try {
        const zohoToken = await zohoAcquireTokenSilent();

        let _request: Request;
        if (typeof request === "string") {
            _request = new Request(request);
        } else {
            _request = request.clone();
        }

        _request.headers.append("Authorization", "Bearer " + accessToken);
        _request.headers.append("P33ZohoAuth", zohoToken);

        const response = await fetch(_request);

        const clonedResponse = response.clone();
        if (!response.ok) {
            console.error(response);
            try {
                const errorResponse = await clonedResponse.json();

                if (errorResponse.errors) {
                    if (Array.isArray(errorResponse.errors)) {
                        // out TAT api error
                        const errors = errorResponse.errors as ApiError[];
                        console.error("API errors: ");
                        console.dir(errors);
                        store.dispatch(setErrors(errors));
                        return Promise.reject(errorResponse);
                    } else {
                        // ASP MVC validation error
                        return Promise.reject(response);
                    }
                }
                if (errorResponse.message) {
                    console.error("API error: ");
                    console.error(errorResponse.message);

                    // known errors
                }
            } catch (jsonError) {
                // response is not JSON
                console.error(
                    "Failed to parse error response JSON:",
                    jsonError,
                );
            }

            if (response.status === 500) {
                store.dispatch(setShowErrorModal(true));
            }
            throw new Error(`${response.status} ${response.statusText}`);
        }

        // may error if there is no body, return empty array
        return response
            .json()
            .catch(() => ({}))
            .finally(() => {
                // reset API errors if everything OK
                store.dispatch(clearErrors());
            });
    } catch (e) {
        const error = String(e);
        const status = parseInt(error.split(" ")[1]);

        switch (status) {
            case 429: {
                const errors: ApiError[] = [
                    {
                        message:
                            "Too many requests! API access denied. Please try again in 5 minutes (aproximately " +
                            new Date(Date.now() + 5 * 60 * 1000).toLocaleString(
                                "en-us",
                                {
                                    hour: "numeric",
                                    minute: "numeric",
                                },
                            ) +
                            ").",
                        severity: ApiErrorSeverity.Severe,
                    },
                ];
                store.dispatch(setErrors(errors));
                break;
            }
            // SHOULDN't HAPPEN BECAUSE WE CHECK EXPIRATION TIME BEFORE REQUEST
            // case 401: {
            //     console.log("Clearing Auth Slice...");
            //     store.dispatch(clearTokens());
            //     console.log("Redirecting to login page...");
            //     redirect("/login");
            //     window.location.replace("/login");
            //     break;
            // }
            default:
                throw new Error(error);
        }

        return Promise.reject(error);
    }
};
