import "src/style/components/Footer.scss";

import { FC, ReactElement, useEffect, useState } from "react";
import { getTarget } from "src/utils/api";
import { Divider, Paper, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

interface IFooterProps {}

interface VersionDto {
    version: string;
}

const Footer: FC<IFooterProps> = (): ReactElement => {
    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const [appInfo, setAppInfo] = useState<VersionDto>();

    const getVersion = async () => {
        setLoading(true);
        const response = await fetch(getTarget() + "/api/Version").finally(() =>
            setLoading(false),
        );
        setAppInfo(await response.json());
    };

    useEffect(() => {
        getVersion();
    }, []);

    return (
        <>
            <Divider />
            <Paper className="footer">
                <Link to="/whats-new">
                    <Typography
                        component="a"
                        color="secondary"
                        sx={[
                            {
                                "&:hover": {
                                    textDecoration: "underline",
                                    color: theme.palette.accent.main,
                                },
                            },
                        ]}
                    >
                        what's new
                    </Typography>
                </Link>
                <Link to="/help">
                    <Typography
                        component="a"
                        color="secondary"
                        sx={[
                            {
                                "&:hover": {
                                    textDecoration: "underline",
                                    color: theme.palette.accent.main,
                                },
                            },
                        ]}
                    >
                        help
                    </Typography>
                </Link>
                <Typography variant="body2">
                    {loading ? "Loading..." : "Version: " + appInfo?.version}
                </Typography>
            </Paper>
        </>
    );
};
export default Footer;
