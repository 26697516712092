import "src/style/HelpPage.scss";

import { ReactElement } from "react";
import { Paper, Typography, useTheme } from "@mui/material";

export default function HelpPage(): ReactElement {
    const theme = useTheme();

    return (
        <div className="help-page">
            <Paper
                className="help-page__content"
                elevation={0}
                sx={{
                    borderRadius: 0,
                    borderColor: theme.palette.primary.main,
                }}
            >
                <div className="help-page__content__title">
                    <Typography variant="h2" align="center" gutterBottom>
                        New to the Timesheet Automation Tool?
                    </Typography>
                    <Typography variant="h6" align="center">
                        This page aims to clarify how to best make use of the
                        tool and streamline your timekeeping experience.
                    </Typography>
                </div>
                <div>
                    <div className="help-page__content__section">
                        <Typography
                            variant="h5"
                            gutterBottom
                            id="accessing-the-tool"
                        >
                            Accessing the tool
                        </Typography>
                        <ol>
                            <Typography component="li">
                                You can access the tool by clicking the "log in"
                                button on the index page or visiting the /home
                                page.
                                <img
                                    src={
                                        require("src/assets/img/screenshots/access1.png")
                                            .default
                                    }
                                    alt="the log in button"
                                />
                            </Typography>
                            <Typography component="li">
                                Next, log into your Microsoft account and
                                consent to the required permissions.
                                <img
                                    src={
                                        require("src/assets/img/screenshots/access2.png")
                                            .default
                                    }
                                    alt="Microsoft login"
                                />
                            </Typography>
                            <Typography component="li">
                                Finally, log into your Zoho account. <br />
                                <strong>
                                    We recommend using the Microsoft option.
                                </strong>
                                <img
                                    src={
                                        require("src/assets/img/screenshots/access3.png")
                                            .default
                                    }
                                    alt="Zoho login"
                                />
                            </Typography>
                            <Typography component="li">
                                You must also consent to the API permissions.
                                <img
                                    src={
                                        require("src/assets/img/screenshots/access4.png")
                                            .default
                                    }
                                    alt="Zoho API consent"
                                />
                            </Typography>
                            <Typography component="li">
                                That's it! You can now get to uploading your
                                file.
                            </Typography>
                        </ol>
                    </div>
                    <div className="help-page__content__section">
                        <Typography
                            variant="h5"
                            gutterBottom
                            id="timekeeping-process"
                        >
                            Timekeeping process
                        </Typography>
                        <ol>
                            <Typography component="li">
                                The timekeeping process starts of the "Upload
                                file" tab of the home screen.
                                <img
                                    src={
                                        require("src/assets/img/screenshots/upload1.png")
                                            .default
                                    }
                                    alt="initial home page"
                                />
                            </Typography>
                            <Typography component="li">
                                The app will fetch the Time Tracker projects
                                assigned to you or your team from Zoho People.
                                If you are assigned to more than one project,
                                use the dropdown in the corner to select the
                                project for the file you need to upload (you can
                                also set a default project in the app settings).
                                <img
                                    src={
                                        require("src/assets/img/screenshots/upload2.png")
                                            .default
                                    }
                                    alt="select project dropdown"
                                />
                            </Typography>
                            <Typography component="li">
                                After selecting the desired project, drag and
                                drop your client timesheet file or click the
                                button to open the file select dialog.
                                <img
                                    src={
                                        require("src/assets/img/screenshots/upload3.png")
                                            .default
                                    }
                                    alt="drag and dropping a file"
                                />
                            </Typography>
                            <Typography component="li">
                                The app will now parse your file. Each row in
                                the file will be mapped to a Zoho People time
                                log.
                                <br />
                                <strong>
                                    IMPORTANT: Only specific client timesheet
                                    formats are accepted by the app.
                                </strong>
                                <br />
                                After generating the list of time logs, you can
                                preview them in the app before adding them into
                                Zoho People.
                                <img
                                    src={
                                        require("src/assets/img/screenshots/upload4.png")
                                            .default
                                    }
                                    alt="time logs table"
                                />
                            </Typography>
                            <Typography component="li">
                                Click the "next" button to continue
                                <img
                                    src={
                                        require("src/assets/img/screenshots/upload5.png")
                                            .default
                                    }
                                    alt="next button"
                                />
                            </Typography>
                            <Typography component="li">
                                The app will now upload your time logs into Zoho
                                People.
                                <br />
                                When finished, a message will appear.
                                <img
                                    src={
                                        require("src/assets/img/screenshots/upload6.png")
                                            .default
                                    }
                                    alt="successful upload"
                                />
                            </Typography>
                            <Typography component="li">
                                Click the "next" button to continue
                            </Typography>
                            <Typography component="li">
                                You can now also submit a timesheet into Zoho
                                People. This will group all your time logs for
                                the month and submit them for approval. You can
                                skip this step if you need to make other
                                adjustments in Zoho People before submitting.
                                <img
                                    src={
                                        require("src/assets/img/screenshots/upload7.png")
                                            .default
                                    }
                                    alt="submit timesheet screen"
                                />
                            </Typography>
                            <Typography component="li">
                                The process is finished. See you next month!
                                <img
                                    src={
                                        require("src/assets/img/screenshots/upload8.png")
                                            .default
                                    }
                                    alt="finished screen"
                                />
                            </Typography>
                        </ol>
                    </div>
                    <div className="help-page__content__section">
                        <Typography
                            variant="h5"
                            gutterBottom
                            id="troubleshooting"
                        >
                            Troubleshooting
                        </Typography>
                        <ul>
                            <Typography component="li" gutterBottom>
                                None of my assigned projects are accepted by the
                                tool.
                            </Typography>
                            <img
                                src={
                                    require("src/assets/img/screenshots/troubleshooting1.png")
                                        .default
                                }
                                alt="error"
                            />
                            <Typography component="em" gutterBottom>
                                This could be due to two reasons: either the
                                client you work for is not supported by the tool
                                OR the People & Talent Team has not assigned you
                                the projects. Contact your manager to find out
                                if you are supposed to use the tool or the P&T
                                Team if the project is missing.
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Job name not recognized.
                            </Typography>
                            <img
                                src={
                                    require("src/assets/img/screenshots/troubleshooting2.png")
                                        .default
                                }
                                alt="error"
                            />
                            <Typography component="em" gutterBottom>
                                No Zoho People job could be mapped to the
                                entry's job name. A job needs to be mapped from
                                Zoho People.
                            </Typography>
                            <Typography component="li" gutterBottom>
                                File contains more than one month.
                            </Typography>
                            <img
                                src={
                                    require("src/assets/img/screenshots/troubleshooting3.png")
                                        .default
                                }
                                alt="error"
                            />
                            <Typography component="em" gutterBottom>
                                If your timesheet contains more than one month
                                of entries, only the majority month will be
                                uploaded.
                            </Typography>
                            <Typography component="em" gutterBottom>
                                No Zoho People job could be mapped to the
                                entry's job name. A job needs to be mapped from
                                Zoho People.
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Job is not assigned to user.
                            </Typography>
                            <img
                                src={
                                    require("src/assets/img/screenshots/troubleshooting4.png")
                                        .default
                                }
                                alt="error"
                            />
                            <Typography component="em" gutterBottom>
                                The job that was mapped to the entry is not
                                assigned to your user or department. Contact the
                                People & Talent team to fix this.
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Invalid Date.
                            </Typography>
                            <img
                                src={
                                    require("src/assets/img/screenshots/troubleshooting5.png")
                                        .default
                                }
                                alt="error"
                            />
                            <Typography component="em" gutterBottom>
                                The entry's date could not be parsed from the
                                timesheet. Use the expected format.
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Fatal error.
                            </Typography>
                            <img
                                src={
                                    require("src/assets/img/screenshots/troubleshooting6.png")
                                        .default
                                }
                                alt="error"
                            />
                            <Typography component="em" gutterBottom>
                                A fatal error occured server-side. The
                                development team is automatically notified and
                                will investigate the issue.
                            </Typography>
                        </ul>
                    </div>
                    <div className="help-page__content__section">
                        <Typography variant="h5" gutterBottom id="app-settings">
                            App settings
                        </Typography>
                        <Typography gutterBottom>
                            You can access the "Settings" tab in the app's home
                            screen to customize your experience. Here are some
                            examples of things you can change:
                        </Typography>
                        <ul>
                            <Typography component="li" gutterBottom>
                                Visuals like dark mode, color theme or
                                background
                            </Typography>
                            <Typography component="li" gutterBottom>
                                Data display options like highlighting errors or
                                grouping by date
                            </Typography>
                            <Typography component="li" gutterBottom>
                                App behaviour like automatically confirming
                                parsed time logs
                            </Typography>
                        </ul>
                    </div>
                </div>
            </Paper>
        </div>
    );
}
