import "src/style/App.scss";

import { useEffect, useState, FC } from "react";

import { HubConnection } from "@microsoft/signalr";
import Layout from "src/components/Layout";
import { Routes, Route } from "react-router-dom";
import LandingPage from "src/pages/LandingPage";
import { useMsal } from "@azure/msal-react";
import LoginPage from "src/pages/LoginPage";
import GuardedRoute from "src/GuardedRoute";
import HomePage from "src/pages/HomePage";
import { handleMessage, initializeSignalRConnection } from "src/utils/signalR";
import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { useAppSelector } from "src/hooks/useAppSelector";
import { themes } from "src/utils/themes";
import NotFoundPage from "src/pages/NotFoundPage";
import HelpPage from "src/pages/HelpPage";
import WhatsNewPage from "src/pages/WhatsNewPage";
import { DEFAULT_THEME, setTheme } from "src/store/reducers/settingsSlice";
import { useAppDispatch } from "src/hooks/useAppDispatch";

interface IAppProps {}

const App: FC<IAppProps> = () => {
    const { instance } = useMsal();
    const dispatch = useAppDispatch();

    const [connection, setConnection] = useState<HubConnection>();

    const theme = useAppSelector((state) => state.settings.theme);
    const lightMode = useAppSelector((state) => state.settings.lightMode);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    const handleTheme = () => {
        if (!theme || !themes[theme]) {
            dispatch(setTheme(DEFAULT_THEME));

            return themes[addDarkModeSuffix(DEFAULT_THEME)];
        } else {
            let _theme = addDarkModeSuffix(theme);
            return themes[_theme];
        }
    };

    const addDarkModeSuffix = (input: string) => {
        let result = input;
        if (lightMode === "") {
            if (prefersDarkMode) {
                result = input + "Dark";
            }
        } else if (lightMode === "dark") {
            result = input + "Dark";
        }

        return result;
    };

    useEffect(() => {
        initializeSignalRConnection(instance).then((result) =>
            setConnection(result),
        );
    }, []);

    useEffect(() => {
        handleMessage(connection);
    }, [connection]);

    useEffect(() => {
        const favicon = document.getElementById("favicon") as HTMLLinkElement;
        if (favicon) {
            const svg = `<svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.55 263.38"><defs><style>.cls-1{fill:${handleTheme().palette.logoSecondary.main};}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:${handleTheme().palette.primary.main};}</style></defs><rect class="cls-2" x=".25" y=".25" width="255.05" height="262.88" rx="41.03" ry="41.03"/><path class="cls-1" d="M106.27,130.74c0,3.68-.88,6.92-2.51,9.58-3.1,5.08-8.9,8.07-16.47,8.07-.59,0-1.16-.02-1.72-.05-11.58-.72-15.48-8.69-16.81-14.18v-54.91c0-5.62,4.57-10.17,10.2-10.17h26.69s0,0,0,0l-30.43,24.04c-.91.72-1.44,1.82-1.44,2.98v16.35h11.94c7.35,0,12.93,2.11,16.42,6.02.39.43.75.88,1.08,1.37,2.02,2.87,3.06,6.53,3.06,10.89Z"/><path class="cls-1" d="M173.52,126.45l-1.03.81c5.66,1.85,10.45,4.74,14.28,8.48v-19.76l-13.25,10.46Z"/><path class="cls-1" d="M175.33,163.22c0,10.72-7.45,17.65-18.98,17.65-19.56,0-19.19-19.43-19.19-19.43h-14.96c-3.38,0-6.12,2.73-6.12,6.1v41.47h-20.78c-14.65,0-26.52-11.84-26.52-26.45v-17.27c5.45,2.2,11.73,3.43,18.68,3.43,21.75,0,37.55-12.88,40.32-32,.02-.09.02-.18.04-.27.27-1.89.4-3.85.4-5.85,0-17.74-9.12-30.66-24.79-35.79l10.12-7.99v-26.32c0-3.37,2.74-6.1,6.12-6.1h24.26c11.83,0,22.54,4.79,30.29,12.52,3.99,3.98,7.19,8.74,9.36,14.03h-39.22c-.81,0-1.6.27-2.23.77l-20.54,16.22c-1.51,1.2-.67,3.63,1.27,3.63h51.85l-30.44,24.05c-.91.72-1.44,1.82-1.44,2.98v16.35h11.93c13.06,0,20.56,6.66,20.56,18.28Z"/></svg>
    `;
            const blob = new Blob([svg], { type: "image/svg+xml" });
            favicon.href = URL.createObjectURL(blob);
        }
    }, [theme]);

    return (
        <ThemeProvider theme={handleTheme()}>
            <CssBaseline enableColorScheme />
            <Layout>
                <Routes>
                    <Route index={true} element={<LandingPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/help" element={<HelpPage />} />
                    <Route path="/whats-new" element={<WhatsNewPage />} />
                    <Route
                        path="/home"
                        element={
                            <GuardedRoute>
                                <HomePage />
                            </GuardedRoute>
                        }
                    />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Layout>
        </ThemeProvider>
    );
};

export default App;
