import "src/style/LandingPage.scss";

import { FC, ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/material";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated,
    useMsal,
} from "@azure/msal-react";

interface ILandingPageProps {}

const LandingPage: FC<ILandingPageProps> = (): ReactElement => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isAuthenticated = useIsAuthenticated();

    const { accounts } = useMsal();

    const skipLandingPage = useAppSelector(
        (state) => state.settings.skipLandingPage,
    );

    const zohoAccessToken = useAppSelector((state) => state.auth.access_token);

    useEffect(() => {
        if (skipLandingPage && isAuthenticated && zohoAccessToken) {
            navigate("/home");
        }
    }, [skipLandingPage, isAuthenticated, zohoAccessToken]);
    return (
        <Box className="landing-page">
            <div
                className="landing-page__content"
                style={{ borderColor: theme.palette.primary.main }}
            >
                <div className="landing-page__content__text">
                    <Typography variant="h3" component="div">
                        Hello{" "}
                        <span style={{ color: theme.palette.secondary.main }}>
                            <AuthenticatedTemplate>
                                {accounts[0]?.name}
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                #principaler
                            </UnauthenticatedTemplate>
                        </span>
                    </Typography>
                    <Typography variant="h5" component="div" gutterBottom>
                        Welcome to the Timesheet Automation Tool
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Ready to simplify your monthly timekeeping process? The
                        Timesheet Automation Tool offered by Principal33
                        seamlessly imports client timesheets into the Zoho
                        People HRMS, replacing the frustrating manual entries of
                        the past.
                    </Typography>
                    <UnauthenticatedTemplate>
                        <Typography variant="body1" gutterBottom mt={1}>
                            Get started by logging into the Microsoft and Zoho
                            systems using the button below.
                        </Typography>
                    </UnauthenticatedTemplate>
                </div>
                <div className="button-container">
                    <AuthenticatedTemplate>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            onClick={() => navigate("/home")}
                        >
                            get started
                        </Button>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            onClick={() => navigate("/login")}
                        >
                            log In
                        </Button>
                    </UnauthenticatedTemplate>

                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        onClick={() => navigate("/help")}
                    >
                        help
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="large"
                        onClick={() => navigate("/whats-new")}
                    >
                        what's new
                    </Button>
                </div>
            </div>
        </Box>
    );
};

export default LandingPage;
