import "src/style/components/Navbar.scss";

import Button from "@mui/material/Button";

import { FC, ReactElement } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from "@azure/msal-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { resetAppSlice } from "src/store/reducers/appSlice";
import { resetUserSlice } from "src/store/reducers/userSlice";
import { resetAuthSlice } from "src/store/reducers/authSlice";

interface INavbarProps {}

const Navbar: FC<INavbarProps> = (): ReactElement => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { instance, accounts } = useMsal();

    const navigate = useNavigate();
    const location = useLocation();

    function handleLogout() {
        dispatch(resetAuthSlice());
        dispatch(resetAppSlice());
        dispatch(resetUserSlice());

        setTimeout(() => {
            instance.logoutRedirect();
        }, 1000);
    }

    return (
        <AppBar position="fixed">
            <Toolbar className="navbar__toolbar">
                <div className="navbar__toolbar__left">
                    <Link to="/">
                        <img
                            className="navbar__toolbar__left__logo"
                            src={`assets/img/${theme.logo}`}
                            alt="logo"
                        />
                        <Typography
                            component="div"
                            sx={{
                                flexGrow: 1,
                                fontFamily: "Syne",
                                fontWeight: 600,

                                ml: 2,
                            }}
                        >
                            Timesheet Automation Tool
                        </Typography>
                    </Link>
                </div>

                <div className="navbar__toolbar__right">
                    <AuthenticatedTemplate>
                        {location.pathname !== "/" && (
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    flexGrow: 1,
                                    fontFamily: "Syne",
                                    fontWeight: 600,
                                }}
                            >
                                {accounts[0]?.name}
                            </Typography>
                        )}
                        <Button
                            color="inherit"
                            size="small"
                            onClick={handleLogout}
                        >
                            sign out
                        </Button>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        {location.pathname !== "/" && (
                            <Button
                                color="inherit"
                                size="small"
                                onClick={() => navigate("/login")}
                            >
                                log in
                            </Button>
                        )}
                    </UnauthenticatedTemplate>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
