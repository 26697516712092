import "src/style/components/SettingsToggle.scss";

import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ReactElement } from "react";

interface ISettingsToggleProps {
    name: string;
    description: string;
    value: string;
    onToggle: (value: string) => void;
    options: { [value: string]: JSX.Element };
    includeEmptyOption?: boolean;
    emptyOptionElement?: JSX.Element;
}

export default function SettingsToggle({
    name,
    description,
    value,
    onToggle,
    options,
    includeEmptyOption,
    emptyOptionElement,
}: ISettingsToggleProps): ReactElement {
    return (
        <div className="settings-toggle">
            <Typography
                gutterBottom
                display="block"
                fontSize={16}
                fontWeight={600}
                sx={{ mb: 1 }}
            >
                {name}
            </Typography>
            <div className="settings-toggle__toggle">
                <ToggleButtonGroup
                    value={value}
                    exclusive
                    onChange={(
                        event: React.MouseEvent<HTMLElement>,
                        newValue: string,
                    ) => onToggle(newValue)}
                    aria-label="text alignment"
                >
                    {includeEmptyOption && (
                        <ToggleButton value="" aria-label="System Default">
                            {emptyOptionElement ? emptyOptionElement : "None"}
                        </ToggleButton>
                    )}
                    {Object.keys(options).map((key) => (
                        <ToggleButton key={key} value={key}>
                            {options[key]}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                <Typography variant="body2">{description}</Typography>
            </div>
        </div>
    );
}
