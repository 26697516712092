import { Typography } from "@mui/material";
import { FC, ReactElement } from "react";
import "style/components/LoadingText.scss";

export interface ILoadingTextProps {
    message?: string;
}

const LoadingText: FC<ILoadingTextProps> = ({
    message = "Hang on . . .",
}): ReactElement => {
    return (
        <div className="loading-text">
            <Typography
                variant="body1"
                component="div"
                gutterBottom
                color="secondary"
            >
                {message}&nbsp;&nbsp;&nbsp; <span className="spinner-console" />
            </Typography>
        </div>
    );
};

export default LoadingText;
