import {
    IPublicClientApplication,
    InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { SignalRProgressMessage } from "src/types";
import { getTarget } from "src/utils/api";
import { authRequest } from "src/utils/auth";
import store from "src/store/store";
import {
    setProgressCurrent,
    setProgressMaximum,
    setProgressMessage,
} from "src/store/reducers/appSlice";

const initializeSignalRConnection = async (
    msalInstance: IPublicClientApplication,
): Promise<HubConnection> => {
    console.log("Initializing SignalR connection...");
    console.log("Authenticating via MSAL...");
    return new Promise<HubConnection>(async (resolve, reject) => {
        await msalInstance
            .acquireTokenSilent(authRequest)
            .then(async (tokenResponse) => {
                console.log("Authentication successful 👍");
                try {
                    const connection = new HubConnectionBuilder()
                        .withUrl(getTarget() + "/hub/progress", {
                            accessTokenFactory: () => tokenResponse.accessToken,
                            headers: {
                                Authorization:
                                    "Bearer " + tokenResponse.accessToken,
                            },
                        })
                        .withAutomaticReconnect()
                        .build() as HubConnection;

                    return resolve(connection);
                } catch (e) {
                    return reject(e);
                }
            })
            .catch((error) => {
                console.error("Error ❌ " + error);
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return msalInstance.acquireTokenRedirect(authRequest);
                }

                // handle other errors
            });
    });
};

const handleMessage = (connection: HubConnection | undefined): void => {
    if (connection) {
        connection
            .start()
            .then(() => {
                connection.on(
                    "TaskProgressChanged",
                    (data: SignalRProgressMessage) => {
                        store.dispatch(setProgressMessage(data.message));
                        data.currentProgress &&
                            store.dispatch(
                                setProgressCurrent(data.currentProgress),
                            );
                        data.maximumProgress &&
                            store.dispatch(
                                setProgressMaximum(data.maximumProgress),
                            );
                    },
                );
                // connection.on("exportDownloadLinkGenerated", async (message) => {
                //   console.log(message);
                //   await props.apiService
                //     .callApi(
                //       "/ExportTimesheets/download/" + message,
                //       null,
                //       "GET",
                //       {
                //         "Content-Type": "application/json",
                //         Accept: "application/zip",
                //       },
                //       { responseType: "arraybuffer" }
                //     )
                //     .then((response) => {
                //       if (response.status === 200) {
                //         const url = window.URL.createObjectURL(
                //           new Blob([response.data])
                //         );
                //         const link = document.createElement("a");
                //         link.href = url;
                //         link.setAttribute("download", message + ".zip");
                //         document.body.appendChild(link);
                //         link.click();
                //       }
                //     })
                //     .finally(() => setExportIsLoading(false));
                // });
            })
            .catch((e) => console.log("Connection failed: ", e));
    }
};

export { initializeSignalRConnection, handleMessage };
